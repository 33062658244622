/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * InspirationInfoItem.jsx
 * Created by Destin Frasier 10/17/22
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useWindowSize, FauxImage, Button } from 'sarsaparilla';

export default function HomepageItem({ name, image, imageAlt, url, overview, id }) {
    let loadedClass = '';
    if (name !== '') {
        loadedClass = 'loaded';
    }

    const { width } = useWindowSize();

    return width <= 768 ? (
        <Button
            isUnstyled
            className="nav-articles-mobile-promo-block-wrap"
            to={url}
            alt={name}
            key={id}
            clickTagCategory="Homepage"
            clickTagAction={`Article ${id} Link (Mobile View)`}
            clickTagLabel="Inspiration & Information Section"
        >
            <div className={`nav-articles-mobile-thumb-wrap ${loadedClass}`}>
                <FauxImage
                    src={`${image}`}
                    altText={`${imageAlt}`}
                    aria-hidden="true"
                    style={{ minHeight: '100%' }}
                />
            </div>
            <div className="nav-articles-mobile-title-wrap">{name}</div>
        </Button>
    ) : (
        <Button
            isUnstyled
            className="nav-articles-thumb-wrap"
            to={url}
            alt={name}
            key={id}
            clickTagCategory="Homepage"
            clickTagAction={`Article ${id} Link`}
            clickTagLabel="Inspiration & Information Section"
        >
            <div className={`nav-articles-thumb ${loadedClass}`}>
                <div className="nav-articles-thumb-title-wrap">{name}</div>

                <FauxImage
                    src={`${image}`}
                    altText={`${imageAlt}`}
                    aria-hidden="true"
                    style={{ minHeight: '180px' }}
                />
                {overview}
            </div>
        </Button>
    );
}

HomepageItem.propTypes = {
    name: PropTypes.string,
    image: PropTypes.string,
    imageAlt: PropTypes.string,
    url: PropTypes.string,
    overview: PropTypes.string,
    id: PropTypes.string,
};

HomepageItem.defaultProps = {
    name: '',
    image: '',
    imageAlt: '',
    url: '',
    overview: '',
    id: '',
};
