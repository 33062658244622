/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useEffect } from 'react';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FlexRow, FlexCol, Button, Icon, Heading } from 'sarsaparilla';

export default function CaseStudies(props) {
    const [caseStudyLinks, setCaseStudyLinks] = useState([]);
    const [productInfoLinks, setProductInfoLinks] = useState([]);

    const fetchCaseStudies = async (invType) => {
        try {
            const resp = await axios.get(
                `${process.env.SN_API}/g_ba2/content/new_inventory?category=${invType.snowCategory}`
            );
            if (Array.isArray(resp?.data?.result)) {
                return resp?.data?.result.filter((r) => {
                    return r.type === 'Case Studies';
                });
            }
            return [];
        } catch (error) {
            return [];
        }
    };

    const fetchInfoSheets = async (invType) => {
        try {
            const resp = await axios.get(
                `${process.env.SN_API}/g_ba2/content/new_inventory?category=${invType.snowCategory}`
            );
            if (Array.isArray(resp?.data?.result)) {
                return resp?.data?.result.filter((r) => {
                    return r.type === 'Info Sheets';
                });
            }
            return [];
        } catch (error) {
            return [];
        }
    };

    useEffect(() => {
        fetchCaseStudies(props.invType).then((result) => {
            setCaseStudyLinks(result);
        });
        fetchInfoSheets(props.invType).then((result) => {
            setProductInfoLinks(result);
        });
    }, [props.invType]);

    const renderCaseStudyLinks = (caseStudies) => {
        const csl = [];
        for (const study of caseStudies) {
            if (
                study.type === 'Case Studies' &&
                study.attachments &&
                study.attachments.length > 0
            ) {
                csl.push(
                    <li key={uniqueId()}>
                        <Button
                            appearance="link"
                            iconAfterElement={<Icon iconName="visibility" />}
                            href={study.attachments[0].link}
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label={`View ${study.name} Study`}
                            gaTrackingId="984902134663"
                        >
                            {study.name}
                        </Button>
                    </li>
                );
            }
        }
        return <ul className={'nav-inv-case-studies-list'}>{csl}</ul>;
    };

    const renderProductInfoSheets = (infos) => {
        const infoLinks = [];
        for (const info of infos) {
            if (info.attachments && info.attachments.length > 0) {
                infoLinks.push(
                    <li key={uniqueId()}>
                        <Button
                            appearance="link"
                            iconAfterElement={<Icon iconName="visibility" />}
                            href={info.attachments[0].link}
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label={`View ${info.name} Study`}
                            gaTrackingId="429607723253"
                        >
                            {info.name}
                        </Button>
                    </li>
                );
            }
        }
        return <ul className={'nav-inv-case-studies-list'}>{infoLinks}</ul>;
    };

    const renderCaseStudyHeader = () => {
        const caseStudyHeading =
            caseStudyLinks.length > 1 ? 'Case Studies' : 'Case Study';
        return (
            <Heading headingLevel={2} appearance="h4">
                {caseStudyHeading}
            </Heading>
        );
    };
    const renderInfoLinksHeader = () => {
        const productInfoHeading =
            productInfoLinks.length > 1 ? 'Product Info Sheets' : 'Product Info Sheet';
        return (
            <Heading headingLevel={2} appearance="h4">
                {productInfoHeading}
            </Heading>
        );
    };

    const renderCaseStudySection = () => {
        return (
            <section className="nav-ni-inventory-types-page-case-studies-section-wrapper">
                <FlexRow className="nav-inv-case-studies-wrap">
                    {caseStudyLinks && caseStudyLinks.length > 0 && (
                        <FlexCol md={6} className="mb-4 mb-lg-0">
                            {caseStudyLinks &&
                                caseStudyLinks.length > 0 &&
                                renderCaseStudyHeader()}
                            {renderCaseStudyLinks(caseStudyLinks)}
                        </FlexCol>
                    )}

                    {productInfoLinks && productInfoLinks.length > 0 && (
                        <FlexCol md={6}>
                            {productInfoLinks &&
                                productInfoLinks.length > 0 &&
                                renderInfoLinksHeader()}
                            {renderProductInfoSheets(productInfoLinks)}
                        </FlexCol>
                    )}
                </FlexRow>
            </section>
        );
    };

    return (
        ((caseStudyLinks && caseStudyLinks.length > 0) ||
            (productInfoLinks && productInfoLinks.length > 0)) &&
        renderCaseStudySection()
    );
}

CaseStudies.propTypes = {
    invType: PropTypes.shape({
        caseStudies: PropTypes.array,
        topKBAs: PropTypes.array,
    }),
};
