/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    Button,
    ContentBlock,
    FlexCol,
    FlexRow,
    Heading,
    Icon,
    InventoryPromoLink,
    Spacer,
} from 'sarsaparilla';
import { merchHomeItems } from 'site-kit';

export function MerchandiseReel() {
    return (
        <ContentBlock className="nav-merchandise">
            <section className="nav-merchandise-reel-outer-wrap">
                <Heading headingLevel={2} appearance="h4" hasUnderline="left">
                    Footprint Merchandise
                </Heading>
                <Spacer size="md" />
                <FlexRow>
                    {merchHomeItems.map((merchItem) => (
                        <FlexCol sm={6} lg={3} className="mb-2" key={merchItem.id}>
                            <InventoryPromoLink
                                imagePath={merchItem.image}
                                imageAlt={merchItem.name}
                                href="/merchandise"
                                heading={merchItem.name}
                                rating={merchItem.rating}
                                reviewCount={merchItem.reviewCount}
                                hasStarRating
                            />
                        </FlexCol>
                    ))}
                </FlexRow>
                <FlexRow>
                    <FlexCol style={{ textAlign: 'right' }}>
                        <Button
                            appearance="link"
                            href="merchandise"
                            iconAfterElement={<Icon iconName="arrow-forward" />}
                        >
                            View All Footprint Merchandise
                        </Button>
                    </FlexCol>
                </FlexRow>
            </section>
        </ContentBlock>
    );
}
