/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable @typescript-eslint/no-unused-vars */

import './VerticalTabs.scss';

import * as React from 'react';
import cx from 'classnames';
import isNil from 'lodash/isNil';
import { useSearchParams } from 'react-router-dom-v5-compat';

import { useComposedRefs } from 'sarsaparilla';

interface VerticalTabsProps {
    children: React.ReactNode;
    defaultActiveIndex?: number;
    activeIndex?: number;
    onChange?: (index: number) => void;
    className?: string;
    'aria-label'?: string;
    id?: string;
    syncWithUrl?: boolean;
    tabListWidth?: number;
    shouldBlockNavigation?: boolean;
    onNavigationBlock?: () => void;
    queryKey?: string;
}

interface TabButtonProps {
    controlsId?: string;
    id: string;
    isDisabled?: boolean;
    isHidden?: boolean;
    isSelected?: boolean;
    label: React.ReactNode;
    onSelect?: () => void;
    onSelectFirst: () => void;
    onSelectLast: () => void;
    onSelectNext: () => void;
    onSelectPrevious: () => void;
    shouldFocus: boolean;
    bonusElement?: React.ReactNode;
}

interface TabPanelProps extends React.HTMLAttributes<HTMLDivElement> {
    isDisabled?: boolean;
    isHidden?: boolean;
    label: React.ReactNode;
    bonusElement?: React.ReactNode;
    queryValue?: string;
}

export const TabButton = React.forwardRef<HTMLButtonElement, TabButtonProps>(
    (props, forwardedRef) => {
        const {
            controlsId,
            id,
            isDisabled,
            isHidden,
            isSelected,
            label,
            onSelect,
            onSelectFirst,
            onSelectLast,
            onSelectNext,
            onSelectPrevious,
            shouldFocus,
            bonusElement,
        } = props;

        const ownRef = React.useRef<HTMLButtonElement>();
        const ref = useComposedRefs(ownRef, forwardedRef);

        React.useEffect(() => {
            if (isSelected && shouldFocus) {
                ownRef.current?.focus();
            }
        }, [isSelected, shouldFocus]);

        const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
            switch (e.key) {
                case 'ArrowDown':
                    e.preventDefault();
                    onSelectNext();
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    onSelectPrevious();
                    break;
                case 'Home':
                    e.preventDefault();
                    onSelectFirst();
                    break;
                case 'End':
                    e.preventDefault();
                    onSelectLast();
                    break;
                default:
                    break;
            }
        };

        return (
            <button
                data-vertical-tab-button
                type="button"
                className={cx({
                    hidden: isHidden,
                    selected: isSelected,
                })}
                disabled={isDisabled || isHidden}
                ref={ref}
                role="tab"
                aria-selected={isSelected}
                tabIndex={isSelected ? undefined : -1}
                id={id}
                aria-controls={controlsId}
                onKeyDown={handleKeyDown}
                onClick={onSelect}
            >
                <span className="label-wrap">{label}</span>

                {bonusElement || null}
            </button>
        );
    }
);

export function TabPanel({
    children,
    isDisabled,
    isHidden,
    label,
    bonusElement,
    queryValue,
    ...rest
}: TabPanelProps) {
    return (
        <div data-tab-panel role="tabpanel" {...rest}>
            {children}
        </div>
    );
}

export function VerticalTabs({
    children,
    defaultActiveIndex = 0,
    activeIndex,
    onChange,
    className,
    'aria-label': ariaLabel,
    id,
    syncWithUrl = true,
    tabListWidth = 204,
    shouldBlockNavigation,
    onNavigationBlock,
    queryKey = 'tab',
}: VerticalTabsProps) {
    const tabs = React.useMemo(
        () =>
            React.Children.map(children, (child, index) => {
                const item = child as React.ReactElement<
                    React.PropsWithChildren<TabPanelProps>
                >;

                if (item.type === TabPanel) {
                    return {
                        queryValue: item.props.queryValue ?? index.toString(),
                        isDisabled: Boolean(item.props.isHidden || item.props.isDisabled),
                    };
                }

                return false;
            }) || [],
        [children]
    );

    // For a given index, find the index of the next tab that is not hidden/disabled
    const nextValidTab = (index: number) => {
        let nextTab = null;
        let i = index;

        while (nextTab === null) {
            i += 1;

            if (i === tabs.length) {
                i = 0;
            }

            if (tabs[i].isDisabled === false) {
                nextTab = i;
            }
        }

        return nextTab;
    };

    // For a given index, find the index of the previous tab that is not hidden/disabled
    const previousValidTab = (index: number) => {
        let previousTab = null;
        let i = index;

        while (previousTab === null) {
            i -= 1;

            if (i === -1) {
                i = tabs.length - 1;
            }

            if (tabs[i].isDisabled === false) {
                previousTab = i;
            }
        }

        return previousTab;
    };

    const [searchParams, setSearchParams] = useSearchParams();

    // Make sure the defaultActiveIndex isn't hidden/disabled
    const defaultStateIndex = tabs[defaultActiveIndex].isDisabled
        ? nextValidTab(defaultActiveIndex)
        : defaultActiveIndex;

    const tabListRef = React.useRef<HTMLDivElement>(null);
    const [shouldFocus, setShouldFocus] = React.useState(false);
    const [stateIndex, setStateIndex] = React.useState(() => {
        if (!syncWithUrl) return defaultStateIndex;

        const tabParamValue = searchParams.get(queryKey);

        const tabParamIndex = tabs.findIndex((t) => t.queryValue === tabParamValue);

        return tabParamIndex < 0 ? defaultStateIndex : tabParamIndex;
    });

    const isControlled = !isNil(activeIndex) && !isNil(onChange);

    const makeButtonId = (index: number) => `${id || 'tabs'}-button-${index}`;
    const makePanelId = (index: number) => `${id || 'tabs'}-panel-${index}`;

    // Called when user clicks or presses arrow keys
    const onSelectTab = React.useCallback(
        (index: number, focus = false) => {
            if (shouldBlockNavigation && onNavigationBlock) {
                onNavigationBlock();
                return;
            }
            if (isControlled) {
                onChange(index);
            } else {
                setStateIndex(index);
            }

            setShouldFocus(focus);

            if (syncWithUrl) {
                setSearchParams(
                    { [queryKey]: tabs[index].queryValue },
                    { replace: true }
                );
            }
        },
        [
            onNavigationBlock,
            isControlled,
            onChange,
            setSearchParams,
            shouldBlockNavigation,
            syncWithUrl,
            tabs,
            queryKey,
        ]
    );

    // Generate all the tab buttons
    const tabButtons = React.Children.map(children, (child, index) => {
        const item = child as React.ReactElement<React.PropsWithChildren<TabPanelProps>>;
        const numChildren = React.Children.count(children);

        if (children && item.type === TabPanel) {
            const isSelected = isControlled
                ? index === activeIndex
                : index === stateIndex;
            const { label, isDisabled, isHidden, bonusElement } = item.props;

            return isHidden ? null : (
                <TabButton
                    label={label}
                    bonusElement={bonusElement}
                    isHidden={isHidden}
                    isDisabled={isDisabled}
                    isSelected={isSelected}
                    shouldFocus={shouldFocus}
                    id={makeButtonId(index)}
                    controlsId={isSelected ? makePanelId(index) : undefined}
                    onSelect={() => onSelectTab(index)}
                    onSelectFirst={() => onSelectTab(nextValidTab(-1), true)}
                    onSelectLast={() => onSelectTab(nextValidTab(numChildren - 2), true)}
                    onSelectNext={() => onSelectTab(nextValidTab(index), true)}
                    onSelectPrevious={() => onSelectTab(previousValidTab(index), true)}
                />
            );
        }

        return null;
    });

    // Generate the visible tab panel. We;re not rendering all the panels to prevent
    // "Multiple elements with the same id" problems caused by multiple panels containing
    // UI bits with build-in ids that not not easily changed
    const currentPanel = isControlled
        ? React.Children.toArray(children)[activeIndex]
        : React.Children.toArray(children)[stateIndex];

    const tabPanel = React.cloneElement(currentPanel as React.ReactElement, {
        id: makePanelId(isControlled ? activeIndex : stateIndex),
        'aria-labelledby': makeButtonId(isControlled ? activeIndex : stateIndex),
    });

    return (
        <div data-vertical-tabs className={className}>
            <div
                data-tab-list
                role="tablist"
                aria-label={ariaLabel}
                ref={tabListRef}
                style={{ minWidth: `${tabListWidth}px` }}
            >
                <div>{tabButtons}</div>
            </div>
            <div data-tab-panels>
                <div>{tabPanel}</div>
            </div>
        </div>
    );
}
