/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Icon, Badge } from 'sarsaparilla';

export default function FacilityFilterBarButton({
    barValue,
    value,
    barPlaceholder,
    placeholder,
    barBadgeCount,
    onClearClick,
    clearButtonLabel,
    onClick,
}) {
    const hasValue = !!barValue || !!value;

    let buttonLabel;

    if (hasValue) {
        buttonLabel = barValue || value;
    } else {
        buttonLabel = barPlaceholder || placeholder;
    }

    return (
        <div data-shared-ffb-button>
            <button
                type="button"
                onClick={onClick}
                className={cx('shared-ffb-button-button', { active: hasValue })}
            >
                <div className={cx({ placeholder: !hasValue })}>
                    {!!barBadgeCount && (
                        <Badge appearance="important">{barBadgeCount}</Badge>
                    )}
                    {buttonLabel}
                </div>
            </button>
            {!!value && !!onClearClick && (
                <div className="clear-value-button-wrap">
                    <button
                        type="button"
                        aria-label={clearButtonLabel || 'Clear filter'}
                        className="clear-value-button"
                        onClick={onClearClick}
                    >
                        <div>
                            <Icon iconName="close-circle" size="sm" />
                        </div>
                    </button>
                </div>
            )}
        </div>
    );
}

FacilityFilterBarButton.propTypes = {
    /** Modal: Tab button: Text under the label when there is no value. e.g. "Add dates ..." */
    placeholder: PropTypes.node,

    /** Modal & Filter bar: display of user selected value. e.g. "Jul 3 - 10" */
    value: PropTypes.node,

    /** Modal: The text used for the 2 clear buttons (1 aria-label and 1 display label). e.g. "Clear dates"  */
    clearButtonLabel: PropTypes.string,

    /** Modal: Callback fired when use clicks a clear button */
    onClearClick: PropTypes.func,

    /** Filter bar: If provided, this overrides the `placeholder` only for the filter bar */
    barPlaceholder: PropTypes.node,

    /** Filter bar: If provided, this overrides `value` only for the filter bar */
    barValue: PropTypes.node,

    /** Filter bar: If provided, adds a badge after the `value` or `barValue` in the filter bar */
    barBadgeCount: PropTypes.number,

    /** Opens the modal at the specified index */
    onClick: PropTypes.func,
};
