/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import moment from 'moment';

import { Badge, Button, FlexCol, FlexRow, Icon, Tooltip } from 'sarsaparilla';

import { storeCampsiteClicked } from 'ui-camping/src/camping/utils/campground';
import {
    CAMPSITE_EARLY_ACCESS_AVAILABLE,
    CAMPSITE_PARTIALLY_AVAILABLE,
    CAMPSITE_UNAVAILABLE,
    CAMPSITE_AVAILABLE,
    ENTER_DATES,
    FIRST_COME_FIRST_SERVED,
} from 'ui-camping/src/shared/constants/availability';
import { ACCESSIBLE_CAMPSITE_TOOLTIP } from 'ui-camping/src/shared/constants/campsite';
import {
    AVAILABILITY_GRID,
    CAMPSITE_LIST,
} from 'ui-camping/src/shared/constants/campground';

import { createComponent, handleClosePopUp } from './common';

export default function campingMapPopup(
    {
        imageUrl,
        title,
        location,
        attributes,
        targetUri,
        featureId,
        entityId,
        availabilityStatus,
        isItinerary,
        onClickFunction,
        accessible,
        campsite_type,
        isInternal,
        startDate,
        endDate,
        isSiteView,
        siteAvailability,
        gridStartDate,
        gridEndDate,
        gridAvailability,
        fullGridStartDate,
        fullGridEndDate,
        selectedTab,
        hasSelectedGridDates,
        calendarStartDate,
        calendarEndDate,
        isNSS,
    },
    deselectFeature
) {
    const isMobile = window.innerWidth < 768;
    const isEnterDates = availabilityStatus === ENTER_DATES;
    const isListTab = selectedTab === CAMPSITE_LIST;
    const isGridTab = selectedTab === AVAILABILITY_GRID;
    let campgroundId = null;

    if (featureId) {
        [campgroundId] = featureId.split('_');
    }

    const isAccessible =
        typeof accessible === 'string' ? JSON.parse(accessible) : accessible;
    let imgUrl = imageUrl === 'null' ? null : imageUrl;

    if (isSiteView && !!siteAvailability && !imgUrl) {
        imgUrl = `${process.env.CDN}/img/global/unavailable/image-unavailable.jpg`;
    }

    const siteAttributes =
        attributes && typeof attributes === 'string'
            ? JSON.parse(attributes)
            : attributes;
    const targetUrl = targetUri === 'null' ? null : targetUri;

    const formatDate = (date) => {
        return moment(date).utc().format('MM/DD/YY');
    };

    const getDateLabel = (start, end) => {
        if (!start || !end) return '';
        return `${formatDate(start)} to ${formatDate(end)}`;
    };

    const statuses = {
        isAvailable: gridAvailability === CAMPSITE_AVAILABLE,
        isUnavailable: gridAvailability === CAMPSITE_UNAVAILABLE,
        isNotReservable: gridAvailability === FIRST_COME_FIRST_SERVED,
        isPartiallyAvailable: gridAvailability === CAMPSITE_PARTIALLY_AVAILABLE,
    };

    const isAvailableSite = siteAvailability === CAMPSITE_AVAILABLE;
    const isAvailableGrid =
        isGridTab && (statuses.isAvailable || (gridStartDate && gridEndDate));
    const isPartiallyAvailableGrid =
        isGridTab && statuses.isPartiallyAvailable && !hasSelectedGridDates;
    const isUnavailableGrid = isGridTab && statuses.isUnavailable;

    const fullGridDatesText = getDateLabel(fullGridStartDate, fullGridEndDate);
    const calendarDatesText = getDateLabel(calendarStartDate, calendarEndDate);
    const gridDatesText = getDateLabel(gridStartDate, gridEndDate);

    const hasCalendarDate = calendarStartDate && calendarEndDate;

    const hasAvailability = siteAvailability === 'Available';
    const titleClassName = imgUrl ? '' : ' popup-title-no-image';
    const siteAvailabilityClassName = `map-popup-${hasAvailability ? '' : 'un'}availability-info`;
    const availability = (
        <div className={siteAvailabilityClassName}>{siteAvailability}</div>
    );

    const isAvailable =
        availabilityStatus === CAMPSITE_AVAILABLE ||
        availabilityStatus === CAMPSITE_EARLY_ACCESS_AVAILABLE ||
        isAvailableGrid ||
        (startDate && endDate) ||
        (gridStartDate && gridEndDate);

    const dateTextContent =
        gridDatesText ||
        ((statuses.isAvailable || statuses.isUnavailable) && calendarDatesText) ||
        ((isAvailableGrid || isUnavailableGrid) && fullGridDatesText);

    const hasDates = hasCalendarDate || hasSelectedGridDates;

    const getAvailabilitySection = () => {
        const isCalendarStyle =
            (!isListTab && hasCalendarDate && !hasSelectedGridDates) ||
            (isListTab && hasCalendarDate);

        const isUnavailable = isCalendarStyle
            ? statuses.isUnavailable
            : isUnavailableGrid;
        const isPartiallyAvailable = isCalendarStyle
            ? statuses.isPartiallyAvailable
            : isPartiallyAvailableGrid;
        const isNotReservable = statuses.isNotReservable && isCalendarStyle;

        return (
            <div className="popup-availability-container">
                <FlexCol>
                    <FlexRow>
                        {hasDates &&
                            !isUnavailable &&
                            !isPartiallyAvailable &&
                            !isNotReservable && (
                                <span className="availability-grid-status-title status-available-grid">
                                    {isListTab && !isEnterDates && availabilityStatus}
                                    {isGridTab && 'Available'}
                                </span>
                            )}
                        {hasDates && isPartiallyAvailable && (
                            <span className="availability-grid-status-title status-partially-available-grid">
                                {isListTab ? availabilityStatus : 'Partially available'}
                            </span>
                        )}
                        {hasDates && isUnavailable && (
                            <span className="availability-grid-status-title status-unavailable-grid">
                                {isListTab ? availabilityStatus : 'Unavailable'}
                            </span>
                        )}
                        {isCalendarStyle && isNotReservable && (
                            <span className="availability-grid-status-title status-not-reservable-grid">
                                First-come, first-served
                            </span>
                        )}
                    </FlexRow>
                    {!isNotReservable && isItinerary && (
                        <FlexRow>
                            {isListTab && (isAvailable || isUnavailable) && (
                                <span>{dateTextContent}</span>
                            )}
                            {!isListTab &&
                                hasDates &&
                                (isAvailable || isUnavailable) &&
                                !isPartiallyAvailable && <span>{dateTextContent}</span>}
                            {hasDates && isPartiallyAvailable && (
                                <span>Check grid for available dates</span>
                            )}
                            {!hasDates && (
                                <span className="status-partially-available-list">
                                    Enter dates for availability
                                </span>
                            )}
                        </FlexRow>
                    )}
                    {isNotReservable && (
                        <FlexRow>
                            <span className="status-not-reservable-list">
                                This site is not available to reserve in advance online or
                                by phone. You must physically arrive at the facility to
                                see if the site is available. The site may be occupied
                                when you arrive.
                            </span>
                        </FlexRow>
                    )}
                </FlexCol>
            </div>
        );
    };

    const onClosePopup = () => {
        if (deselectFeature) deselectFeature();
        handleClosePopUp();
    };

    const providedStartDate =
        startDate ||
        (moment.isMoment(gridStartDate) && gridStartDate.isValid()
            ? gridStartDate
            : null) ||
        (calendarStartDate ? moment.utc(calendarStartDate) : null);
    const providedEndDate =
        endDate ||
        (moment.isMoment(gridEndDate) && gridEndDate.isValid() ? gridEndDate : null) ||
        (calendarEndDate ? moment.utc(calendarEndDate) : null);

    const popup = (
        <div className="map-popup">
            <div className="map-popup-message">
                <Button
                    className="close-pop-up-btn"
                    appearance="subtle"
                    size="sm"
                    shouldFitContainer={false}
                    onClick={onClosePopup}
                    iconBeforeElement={<Icon iconName="close" />}
                    screenReaderTextBefore="Close the modal"
                    gaTrackingId="681762123001"
                />

                {imgUrl ? (
                    <div
                        aria-hidden="true"
                        className="map-popup-preview-image"
                        style={{ backgroundImage: `url("${imgUrl}")` }}
                    >
                        {isSiteView && !!siteAvailability && availability}
                    </div>
                ) : null}

                <div className={`map-popup-title${titleClassName}`}>{title}</div>

                {location ? <p className="map-popup-text">{location}</p> : null}

                {isAccessible || !!siteAttributes?.length ? (
                    <ul>
                        {isAccessible && (
                            <li className="accessible-icon-list">
                                <Tooltip content={ACCESSIBLE_CAMPSITE_TOOLTIP}>
                                    <Icon
                                        iconName="accessible"
                                        size="sm"
                                        className="accessible-icon"
                                    />
                                </Tooltip>
                            </li>
                        )}
                        {isInternal && campsite_type ? <li>{campsite_type}</li> : null}
                        {siteAttributes
                            ? siteAttributes.map(({ key, value }) => {
                                  const hasAvailabilityAttribute = key === 'availability';
                                  if (hasAvailabilityAttribute) return null;
                                  return <li key={key}>{value}</li>;
                              })
                            : null}
                    </ul>
                ) : null}

                {(isAccessible || !!siteAttributes?.length) &&
                    !isSiteView &&
                    !isItinerary &&
                    !isNSS &&
                    !isInternal &&
                    getAvailabilitySection()}

                {((!isItinerary && targetUrl) || isAvailable) && !isInternal ? (
                    <div className="map-popup-button-wrap">
                        <div className="map-popup-buttons">
                            {!isItinerary && !isSiteView && targetUrl && (
                                <div>
                                    <Button
                                        className={isEnterDates ? 'align-right' : ''}
                                        href={targetUrl}
                                        appearance="link"
                                        onClick={() =>
                                            storeCampsiteClicked(campgroundId, entityId)
                                        }
                                        rel="noopener noreferrer"
                                        size="md"
                                        gaTrackingId="317756999568"
                                        gaTrackingValue={title}
                                    >
                                        View Details
                                    </Button>
                                    {featureId === '250860_583320' && (
                                        <Badge appearance="info">Portage required</Badge>
                                    )}
                                </div>
                            )}

                            {hasDates &&
                                !statuses.isNotReservable &&
                                (statuses.isAvailable ||
                                    isAvailableGrid ||
                                    isAvailableSite) && (
                                    <div>
                                        <Button
                                            id={`book-now-button-${entityId}`}
                                            className={`list-map-book-now-button-tracker ${
                                                isMobile ? 'mobile' : 'desktop'
                                            }`}
                                            onClick={() =>
                                                onClickFunction({
                                                    [entityId]: {
                                                        startDate: providedStartDate,
                                                        endDate: providedEndDate,
                                                    },
                                                })
                                            }
                                            appearance="primary"
                                            size="xs"
                                            rel="noopener noreferrer"
                                            gaTrackingId="253459600897"
                                            gaTrackingValue={title}
                                        >
                                            Add to Cart
                                        </Button>
                                    </div>
                                )}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );

    return createComponent(popup);
}
