/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as turf from '@turf/turf';
import { MapHelper } from 'sarsaparilla';
import { facilityTypes } from '../constants';
import { getMapIcon } from './utils';

export const processPoints = (geojson) => {
    const processedGeojson = { ...geojson };
    processedGeojson.features = [];
    geojson.features.forEach((feature) => {
        const processed = { ...feature };

        if (!processed.properties.symbol) {
            const type = processed?.properties?.type?.toString();
            const symbol = facilityTypes[type || 'default'].symbol;
            processed.properties.symbol = symbol;
        }

        processedGeojson.features.push(processed);
    });
    return processedGeojson;
};

const convertAssetToFeature = (assetProps) => {
    const {
        name,
        entityId: id,
        featureId,
        mapIcon: icon,
        latitude,
        longitude,
        asset,
    } = assetProps;
    const props = { name, id, featureId, icon, asset };

    const lat = typeof latitude === 'string' ? parseFloat(latitude) : latitude;
    const lng = typeof longitude === 'string' ? parseFloat(longitude) : longitude;

    const feature = turf.point([lng, lat], props);
    return feature;
};

export const convertSearchResultToGeojson = (searchResultList = []) => {
    const features = [];
    let asset = null;
    searchResultList
        ?.filter((f) => f.longitude && f.latitude)
        .forEach((location) => {
            const {
                name,
                entity_id,
                entity_type,
                longitude,
                latitude,
                city,
                state_code,
                preview_image_url,
            } = location;
            asset = {
                name,
                title: name,
                mapIcon: getMapIcon(location),
                targetUri: MapHelper.getTargetUri(location),
                entityId: entity_id,
                entityType: entity_type,
                featureId: `${entity_type}_${entity_id}`,
                longitude,
                latitude,
            };

            if (preview_image_url) {
                asset.imageUrl = preview_image_url;
                asset.imageTitle = 'Preview Image';
            }

            if (city || state_code)
                asset.location = `${city || ''}${city && state_code ? ', ' : ''}${state_code || ''}`;

            const attributes = [];
            if (location.availability === 'unavailable') {
                attributes.push({
                    key: 'availability',
                    value: 'No availability for your dates',
                });
            }

            if (location.campsites_count) {
                const campsiteCount = parseInt(location.campsites_count, 10);
                const suffix = campsiteCount > 1 ? 'sites' : 'site';
                attributes.push({
                    key: 'campsite_count',
                    value: `${location.campsites_count} ${suffix}`,
                });
            }

            if (location.tours_count) {
                const tourCount = parseInt(location.tours_count, 10);
                const suffix = tourCount > 1 ? 'tours' : 'tour';
                attributes.push({
                    key: 'tour_count',
                    value: `${location.tours_count} ${suffix}`,
                });
            }

            asset.attributes = attributes;
            asset.asset = location;
            const feature = convertAssetToFeature(asset);
            features.push(feature);
        });

    return turf.featureCollection(features);
};

export const parseVenuesResult = (searchResultList = []) => {
    const venuePins = {
        'wedding-ceremony': 'rec-icon-venue-wedding',
        'small-gathering': 'rec-icon-venue-small-gathering',
        shooting: 'rec-icon-venue-shooting',
        other: 'rec-icon-venue-other',
        'large-reception': 'rec-icon-venue-large-reception',
        hunting: 'rec-icon-venue-hunting',
        golfing: 'rec-icon-venue-golfing',
        'athletic-activity': 'rec-icon-venue-athletic-activity',
    };

    const features = searchResultList
        .filter((feat) => feat.lat_long && feat.lat_long.long && feat.lat_long.lat)
        .map((location) => {
            const lat = location.lat_long.lat;
            const lng = location.lat_long.long;
            const tag = location.venueTags?.[0];

            const props = {
                name: location.name,
                featureId: location.id,
                facilityId: location.facility_id,
                id: location.id,
                tags: location.venueTags,
                icon: venuePins[tag] || 'rec-icon-venue-other',
                symbol: venuePins[tag] || 'rec-icon-venue-other',
            };

            if (location.city && location.state) {
                props.location = `${location.city}, ${location.state}`;
            } else if (location.city) {
                props.location = location.city;
            } else if (location.state) {
                props.location = location.state;
            }

            return turf.point([lng, lat], props);
        });

    return turf.featureCollection(features);
};

export const parseEvFeatures = (evResults = []) => {
    if (!evResults?.length) return evResults;

    const evFeatures = evResults.map((ev) => {
        const { station_info, longitude, latitude } = ev || {};
        if (!station_info || !latitude || !longitude) return null;

        const level1 = station_info['EV Level1 EVSE Num'];
        const level2 = station_info['EV Level2 EVSE Num'];
        const dcLevel = station_info['EV DC Fast Count'];

        let icon = 'ev-charging-level-na';
        let level = 'other-level';
        let levelPorts = 0;

        if (level1) {
            icon = 'ev-charging-level-01';
            level = 'level-01';
            levelPorts = level1;
        } else if (level2) {
            icon = 'ev-charging-level-02';
            level = 'level-02';
            levelPorts = level2;
        } else if (dcLevel) {
            icon = 'ev-charging-level-03';
            level = 'level-03';
            levelPorts = dcLevel;
        }

        const fullAddress = `${station_info['Street Address']}, ${station_info.City}, ${station_info.state} ${station_info.zip}`;

        const evProps = {
            ...ev,
            icon,
            level,
            adapter: station_info['EV Connector Types'] || 'other',
            network: station_info['EV Network'] || 'other',
            hours: station_info['Access Days Time'],
            price: station_info['EV Pricing'],
            address: station_info['Street Address'],
            maxVehicle: station_info['Maximum Vehicle Class'],
            fullAddress,
            levelPorts,
        };
        return turf.point([parseFloat(longitude), parseFloat(latitude)], evProps);
    });

    const filteredEvFeatures = evFeatures.filter(
        (evFeature) => evFeature.properties.levelPorts > 0
    );

    return filteredEvFeatures;
};
