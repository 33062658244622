/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants';

const INITSTATE = {
    searchResults: {},
};

export default function search(state = INITSTATE, action) {
    let st = state;

    switch (action.type) {
        case types.START_GET_SEARCH_RESULTS:
            st = { ...state };
            st.loading = true;
            return st;

        case types.END_GET_SEARCH_RESULTS:
            st = { ...state };
            st.loading = false;
            return st;

        case types.START_GET_GEO_SEARCH_RESULTS:
            st = { ...state };
            st.geoLoading = true;
            return st;

        case types.END_GET_GEO_SEARCH_RESULTS:
            st = { ...state };
            st.geoLoading = false;
            return st;

        case types.GET_SEARCH_RESULTS:
            st = { ...state };

            if (!action.payload.error) {
                if ('geoResults' in action.payload) {
                    st.geoResults = action.payload.geoResults;
                } else {
                    st.results = action.payload.results;

                    if ('total' in action.payload) {
                        st.total = action.payload.total;
                    }

                    if ('radius' in action.payload) {
                        st.radius = action.payload.radius;
                    } else {
                        st.radius = null;
                    }

                    if ('latitude' in action.payload) {
                        st.lat = action.payload.latitude;
                    } else {
                        st.lat = null;
                    }

                    if ('entity_id' in action.payload) {
                        st.entity_id = action.payload.entity_id;
                    } else {
                        st.entity_id = null;
                    }

                    if ('entity_id' in action.payload) {
                        st.entity_id = action.payload.entity_id;
                    } else {
                        st.entity_id = null;
                    }

                    if ('entity_type' in action.payload) {
                        st.entity_type = action.payload.entity_type;
                    } else {
                        st.entity_type = null;
                    }

                    if ('longitude' in action.payload) {
                        st.lng = action.payload.longitude;
                    } else {
                        st.lng = null;
                    }

                    if ('lat_sw' in action.payload) {
                        st.lat_sw = action.payload.lat_sw;
                    } else {
                        st.lat_sw = null;
                    }

                    if ('lng_sw' in action.payload) {
                        st.lng_sw = action.payload.lng_sw;
                    } else {
                        st.lng_sw = null;
                    }

                    if ('lat_ne' in action.payload) {
                        st.lat_ne = action.payload.lat_ne;
                    } else {
                        st.lat_ne = null;
                    }

                    if ('lng_ne' in action.payload) {
                        st.lng_ne = action.payload.lng_ne;
                    } else {
                        st.lng_ne = null;
                    }

                    if ('cursor_mark' in action.payload) {
                        st.cursor_mark = action.payload.cursor_mark;
                    } else {
                        st.cursor_mark = null;
                    }

                    if ('start' in action.payload) {
                        st.start = action.payload.start;
                    } else {
                        st.start = null;
                    }

                    if ('size' in action.payload) {
                        st.size = action.payload.size;
                    } else {
                        st.size = null;
                    }

                    if ('spelled_correctly' in action.payload) {
                        st.spelled_correctly = action.payload.spelled_correctly;
                    } else {
                        st.spelled_correctly = null;
                    }

                    if ('spelling_collation' in action.payload) {
                        st.spelling_collation = action.payload.spelling_collation;
                    } else {
                        st.spelling_collation = null;
                    }

                    if ('spelling_autocorrected' in action.payload) {
                        st.spelling_autocorrected = action.payload.spelling_autocorrected;
                    } else {
                        st.spelling_autocorrected = null;
                    }

                    if ('spelling_suggestions' in action.payload) {
                        st.spelling_suggestions = action.payload.spelling_suggestions;
                    } else {
                        st.spelling_suggestions = null;
                    }

                    if ('exact' in action.payload) {
                        st.exact = action.payload.exact;
                    } else {
                        st.exact = null;
                    }

                    if (action.payload.location) {
                        st.location = action.payload.location;
                        st.placename = st.location;
                    } else {
                        st.placename = null;
                        st.user_placename = null;
                    }

                    if ('query' in action.payload) {
                        st.query = action.payload.query;
                    } else {
                        st.query = null;
                    }
                }

                if (action.message) {
                    st.message = action.message;
                }

                delete st.err;
            } else {
                st.err = action.err;
            }

            return st;

        case types.GET_EXACT_SEARCH_RESULT:
            st = { ...state };
            st.exactResult = action.payload.exactResult;
            return st;

        case types.GET_SEARCH_ERROR:
            st = { ...state };
            st.err = action.err;
            return st;

        case types.GET_BOUNDARY_SEARCH_RESULT:
            st = { ...state };
            if (!action.payload.error) {
                st.boundaryResults = action.payload.boundaryResults;
            }
            return st;

        case types.GET_BOUNDARY_SEARCH_RESULT_ERROR:
            st = { ...state };
            st.err = action.err;
            return st;

        case types.GET_TRAILS_SEARCH_RESULT:
            st = { ...state };
            if (!action.payload.error) {
                st.trailResults = action.payload.trailResults;
            }
            return st;

        case types.GET_TRAILS_SEARCH_RESULT_ERROR:
            st = { ...state };
            st.err = action.err;
            return st;

        case types.GET_EV_SEARCH_RESULT:
            st = { ...state };
            if (!action.payload.error) {
                st.evResults = action.payload.evResults;
            }
            return st;

        case types.GET_EV_SEARCH_RESULT_ERROR:
            st = { ...state };
            st.err = action.err;
            return st;

        default:
            return st;
    }
}
