/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import { Icon, Checkbox, useFlags } from 'sarsaparilla';

import { CAMPSITE_ACCESSIBLE_FILTER as accessibilityFilter } from 'ui-search/src/constants';
import { toggleSearchFilter, updateSearchCriteria } from 'ui-search/src/actions/search';
import { toggleFilterGroups } from 'ui-search/src/utils/filters';

export default function AccessibleCheckbox({ iconSize }) {
    const { useNewCampingFilters } = useFlags();
    const search = useSelector((state) => state?.search);
    const dispatch = useDispatch();

    const handleChange = () => {
        if (useNewCampingFilters) {
            const params = toggleFilterGroups({ key: 'accessible' }, search);
            dispatch(updateSearchCriteria('fg', params));
        } else {
            dispatch(toggleSearchFilter('campsiteAmenities', accessibilityFilter));
        }
    };
    const fgValues = get(search, 'fg', []);

    let isChecked =
        search?.uiFilters?.campsiteAmenities?.hasOwnProperty(accessibilityFilter.key) ||
        false;
    if (useNewCampingFilters) {
        isChecked = fgValues.indexOf('accessible') !== -1;
    }

    return (
        <div className="accessible-camping-wrap">
            <Checkbox
                id={'accessible-camping'}
                label={
                    <>
                        <Icon iconName="accessible" size={iconSize} />
                        <span>Accessible camping</span>
                    </>
                }
                isChecked={isChecked}
                onChange={handleChange}
            />
        </div>
    );
}

AccessibleCheckbox.propTypes = {
    iconSize: PropTypes.string,
};

AccessibleCheckbox.defaultProps = {
    iconSize: 'md',
};
