/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Button, SecurityHelper } from 'sarsaparilla';

interface HelpSupportProps {
    isExternalCSR: boolean;
}

export function HelpSupport({ isExternalCSR = false }: HelpSupportProps) {
    const gaCategory = 'Footer';
    const gaLabel = 'Let Us Help You List';
    const isLoggedIn = SecurityHelper.isLoggedIn();
    const SERVICE_NOW_EXTERNAL_URL = process.env.SN_KB_EXT_URL;

    return (
        <ul className="nav-footer-list">
            {!isExternalCSR && isLoggedIn && (
                <li>
                    <Button
                        appearance="link"
                        href="/account/profile"
                        clickTagCategory={gaCategory}
                        clickTagAction="Your Account link"
                        clickTagLabel={gaLabel}
                    >
                        Your Account
                    </Button>
                </li>
            )}

            {!isExternalCSR && isLoggedIn && (
                <li>
                    <Button
                        appearance="link"
                        href="/account/reservations"
                        clickTagCategory={gaCategory}
                        clickTagAction="Your Reservations link"
                        clickTagLabel={gaLabel}
                    >
                        Your Reservations
                    </Button>
                </li>
            )}

            {SERVICE_NOW_EXTERNAL_URL ? (
                <li>
                    <Button
                        appearance="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={SERVICE_NOW_EXTERNAL_URL}
                        aria-label="Visit the help center"
                        clickTagCategory={gaCategory}
                        clickTagAction="Help Center link"
                        clickTagLabel={gaLabel}
                    >
                        Help Center
                    </Button>
                </li>
            ) : null}

            {SERVICE_NOW_EXTERNAL_URL ? (
                <li>
                    <Button
                        appearance="link"
                        href={SERVICE_NOW_EXTERNAL_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Link to contact us for support"
                        clickTagCategory={gaCategory}
                        clickTagAction="Contact Us link"
                        clickTagLabel={gaLabel}
                    >
                        Contact
                    </Button>
                </li>
            ) : null}

            <li>
                <Button
                    appearance="link"
                    href="/feedback"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Link to feedback Page"
                    clickTagCategory={gaCategory}
                    clickTagAction="Submit Feedback link"
                    clickTagLabel={gaLabel}
                >
                    Submit Feedback
                </Button>
            </li>

            <li>
                <Button
                    appearance="link"
                    href="/responsible-disclosure"
                    clickTagCategory={gaCategory}
                    clickTagAction="Responsible Disclosures link"
                    clickTagLabel={gaLabel}
                >
                    Responsible Disclosure
                </Button>
            </li>
        </ul>
    );
}
