/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * Implements Mapbox API to add custom controls along Mapbox out of the
 * controls. This control provides checkboxes to show/hide layers. The
 * constructor takes a layer id and an array of objects that have 'icons' and
 * 'label' properties.
 */
export default class MapSearchLayerControl {
    constructor(layerIds, layers) {
        this._layerIds = layerIds;
        this._layers = layers;
        this._onClickMapLayer = this._onClickMapLayer.bind(this);
        this._onClickMapLayerItem = this._onClickMapLayerItem.bind(this);
        this._onClickMapView = this._onClickMapView.bind(this);
        this._onClickSatelliteView = this._onClickSatelliteView.bind(this);
    }

    onAdd(map) {
        this._map = map;
        this._container = document.createElement('div');

        this._mainContainer = document.createElement('div');
        this._mainContainer.className = 'mapboxgl-ctrl mapboxgl-ctrl-text-group';

        this._mapViewButton = document.createElement('button');
        this._mapViewButton.setAttribute('aria-label', 'Toggle Map View');
        this._mapViewButton.setAttribute('data-ga-tracking-id', '779621461344');
        this._mapViewButton.type = 'button';
        this._mapViewButton.innerHTML = 'Map';
        this._mapViewButton.addEventListener('click', this._onClickMapView);
        this._mainContainer.appendChild(this._mapViewButton);

        this._satelliteViewButton = document.createElement('button');
        this._satelliteViewButton.setAttribute('aria-label', 'Toggle Satellite View');
        this._satelliteViewButton.setAttribute('data-ga-tracking-id', '779621461344');
        this._satelliteViewButton.type = 'button';
        this._satelliteViewButton.innerHTML = 'Satellite';
        this._satelliteViewButton.addEventListener('click', this._onClickSatelliteView);
        this._mainContainer.appendChild(this._satelliteViewButton);

        // Now add the layer list.
        if (this._layers) {
            const me = this;

            this._mapLayerButton = document.createElement('button');
            this._mapLayerButton.addEventListener('click', this._onClickMapLayer);
            this._mapLayerButton.innerHTML = 'Layers';
            this._mapLayerButton.setAttribute('aria-label', 'Show Layer View Options');
            this._mapLayerButton.setAttribute('data-ga-tracking-id', '779621461344');
            this._mapLayerButton.type = 'button';

            this._mainContainer.appendChild(this._mapLayerButton);

            this._supportContainer = document.createElement('div');
            this._supportContainer.className =
                'mapboxgl-ctrl mapboxgl-ctrl-text-group mapboxgl-ctrl-sub-group not-shown';

            this._container.appendChild(this._mainContainer);

            this._layers.forEach((layer, i) => {
                const layerCheckbox = document.createElement('input');
                const layerLabel = document.createElement('label');

                layerCheckbox.addEventListener('change', () => {
                    me._onClickMapLayerItem(
                        layerCheckbox.checked
                            ? layerCheckbox.setAttribute(
                                  'data-ga-map-filter-checkbox-state',
                                  'False'
                              )
                            : layerCheckbox.setAttribute(
                                  'data-ga-map-filter-checkbox-state',
                                  'True'
                              )
                    );
                });

                layerCheckbox.checked = true;
                layerCheckbox.id = `layer_checkbox_${i}`;
                layerCheckbox.name = `layer_checkbox_${i}`;
                layerCheckbox.type = 'checkbox';
                layerCheckbox.setAttribute('data-ga-tracking-id', '932012822903');
                layerCheckbox.setAttribute('data-ga-check-label', layer.label);

                this._supportContainer.appendChild(layerCheckbox);

                layerLabel.htmlFor = `layer_checkbox_${i}`;
                layerLabel.innerHTML = layer.label;

                this._supportContainer.appendChild(layerLabel);
            });

            this._container.appendChild(this._supportContainer);
        } else {
            this._container.appendChild(this._mainContainer);
        }

        return this._container;
    }

    _onClickMapView() {
        this._map.setLayoutProperty('fixed-satellite', 'visibility', 'none');
    }

    _onClickSatelliteView() {
        this._map.setLayoutProperty('fixed-satellite', 'visibility', 'visible');
    }

    _onClickMapLayer() {
        this._supportContainer.classList.toggle('not-shown');
    }

    _onClickMapLayerItem() {
        const icons = [];

        this._layers.forEach((layer, i) => {
            const checkbox = document.getElementById(`layer_checkbox_${i}`);

            if (checkbox && checkbox.checked) {
                layer.icons.forEach((icon) => {
                    icons.push(icon);
                });
            }
        });
        this._layerIds.forEach((layerId) => {
            this._map.setFilter(layerId, ['in', 'icon', ...icons]);
        });
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}
