/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import cx from 'classnames';

import { Heading, Icon, Box, DisplayHtmlContent } from 'sarsaparilla';

import type { NeedToKnowListItemType } from './NeedToKnowList';

export default function NeedToKnowListItem({
    title,
    priority = 'low',
    description,
    headingLevel = 3,
}: NeedToKnowListItemType) {
    const isHighPriority = priority === 'high';

    return (
        <li data-shared-ntk-list-item>
            <Box
                alignX="center"
                alignY="center"
                className={cx('shared-ntk-list-item-bullet', {
                    'high-priority': isHighPriority,
                    'low-priority': !isHighPriority,
                })}
            >
                <Icon iconName={isHighPriority ? 'warning' : 'info'} />
            </Box>
            {title ? (
                <Heading
                    data-shared-ntk-list-item-heading
                    headingLevel={headingLevel}
                    appearance="small"
                >
                    {title}
                </Heading>
            ) : null}
            <DisplayHtmlContent html={description} />
        </li>
    );
}

NeedToKnowListItem.propTypes = {
    title: PropTypes.string,
    priority: PropTypes.string,
    description: PropTypes.node,
    headingLevel: PropTypes.number,
};
