/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom-v5-compat';

export default function CardLink({
    id,
    linkUrl,
    ariaLabel,
    children,
    isLinkToSameService,
}) {
    if (isLinkToSameService) {
        return (
            <Link
                id={id}
                className="card-link"
                aria-label={ariaLabel}
                to={linkUrl}
                tabIndex={-1}
            >
                {children}
            </Link>
        );
    }

    return (
        <a
            id={id}
            className="card-link"
            aria-label={ariaLabel}
            href={linkUrl}
            tabIndex={-1}
        >
            {children}
        </a>
    );
}

CardLink.propTypes = {
    id: PropTypes.string,
    linkUrl: PropTypes.string.isRequired,
    ariaLabel: PropTypes.string.isRequired,
    children: PropTypes.node,

    /** If true, renders react router Links to preserve redux state */
    isLinkToSameService: PropTypes.bool,
};
