/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { useWindowSize, Stack, Spacer, useStickySidebar } from 'sarsaparilla';

import { MobileProceedToCart } from './MobileProceedToCart';

export function OrderDetailsLayout({ children, summaryElement, ...rest }) {
    const windowSize = useWindowSize();
    const isDesktopSize = windowSize.width >= 768;

    const { containerRef, stuckStatus } = useStickySidebar(100, isDesktopSize);

    return (
        <>
            <div {...rest} data-component="OrderDetailsLayout">
                <div>
                    <div data-shared-odl-children>
                        <Stack space="lg">{children}</Stack>
                    </div>
                    <div data-shared-summary-el-wrapper ref={containerRef}>
                        <div
                            data-shared-summary-el-wrapper-inner
                            className={cx({
                                'stuck-top': stuckStatus === 'top',
                                'stuck-bottom': stuckStatus === 'bottom',
                            })}
                        >
                            {summaryElement}
                        </div>
                    </div>
                </div>
            </div>

            <Spacer size="lg" />

            {!isEmpty(summaryElement?.props) && (
                <MobileProceedToCart {...summaryElement.props} />
            )}
        </>
    );
}

OrderDetailsLayout.propTypes = {
    summaryElement: PropTypes.node,
    children: PropTypes.node,
};
