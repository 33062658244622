/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useEffect, forwardRef } from 'react';
import { Button, Icon, Text } from 'sarsaparilla';
import cx from 'classnames';
import { searchDate } from 'ui-search/src/utils/dateUtils';
import isEmpty from 'lodash/isEmpty';
import { FiltersProps } from './FlexDatesMain';

type Ref = HTMLButtonElement | null;

export interface MonthsOptionsProps {
    month: string;
    monthShort: string;
    year: string;
    value: string;
}

export type FlexDatesInputProps = {
    filters: FiltersProps;
    openPortal: boolean;
    onClear: () => void;
    setOpenPortal: React.Dispatch<React.SetStateAction<boolean>>;
    monthsOptions: Array<MonthsOptionsProps>;
    showClear: boolean | undefined;
    startRange?: string;
    endRange?: string;
};

export function RangeValue({ value, showValue }: { value: string; showValue?: boolean }) {
    return (
        <div className="flex-range-value-container">
            <Text fontWeight="semibold" className="flex-range-value-plus" size="xs">
                +
            </Text>
            {showValue && (
                <Text fontWeight="semibold" className="flex-range-value-text" size="xs">
                    {value}
                </Text>
            )}
            <Text fontWeight="semibold" className="flex-range-value-less" size="xs">
                _
            </Text>
        </div>
    );
}

function ButtonText(children: string | JSX.Element, isJsx?: boolean): JSX.Element {
    if (isJsx) {
        return <div className="flex-dates-text-wrapper">{children}</div>;
    }
    return <div className="flex-dates-input-text">{children}</div>;
}

function createDatesComponent(
    start: string,
    end: string,
    startRange?: string,
    endRange?: string
): JSX.Element {
    return (
        <>
            <div className="flex-date-start" aria-label={`Start date: ${start}`}>
                {start}
                {startRange ? <RangeValue value={startRange} showValue /> : ''}
            </div>
            <div className="flex-dates-text-arrow">
                <Icon iconName="arrow-forward" />
            </div>
            <div className="flex-date-start" aria-label={`End date: ${end}`}>
                {end}
                {endRange ? <RangeValue value={endRange} showValue /> : ''}
            </div>
        </>
    );
}

const FlexDatesInput = forwardRef<Ref, FlexDatesInputProps>(
    (
        { filters, openPortal, setOpenPortal, monthsOptions, onClear, showClear },
        triggerRef
    ) => {
        const [buttonLabel, setButtonLabel] = useState(ButtonText('When'));
        const [hideCalendarIcon, setHideCalendar] = useState(false);

        const handleUpdateLabel = () => {
            const { duration, startDate, endDate, startRange, endRange } = filters;
            let { months } = filters;

            months =
                months
                    ?.map((i) => searchDate(i))
                    ?.sort((a, b) => a.compare(b))
                    ?.map((i) => searchDate(i).format('MM-YYYY')) || null;

            if (months && duration) {
                const monthsString = months
                    .map((item: string) => {
                        const find = monthsOptions.find((v) => v.value === item);
                        if (find) {
                            return find.monthShort;
                        }
                        return '';
                    })
                    ?.join(', ');
                const durationString = `${duration} ${
                    duration === '1' ? 'night' : 'nights'
                }`;
                setHideCalendar(false);
                setButtonLabel(ButtonText(`${durationString} - ${monthsString}`));
            }
            if (startDate && endDate) {
                const start = searchDate(startDate).format('MMM DD');
                const end = searchDate(endDate).format('MMM DD');
                const datesComp = createDatesComponent(start, end, startRange, endRange);
                setHideCalendar(true);
                setButtonLabel(ButtonText(datesComp, true));
            }
            if (isEmpty(filters)) {
                setHideCalendar(false);
                setButtonLabel(ButtonText('When'));
            }
        };

        useEffect(() => {
            handleUpdateLabel();
            // eslint-disable-next-line
        }, [filters]);

        const hasFilters =
            (filters.startDate && filters.endDate) ||
            (filters.duration && filters.months && filters.months.length > 0);

        // Find the location of the page for GA
        const isSearchPage = window.location.pathname.includes('search');

        return (
            <>
                <Button
                    isUnstyled
                    ref={triggerRef}
                    className="rec-select flex-dates-input"
                    onClick={() => {
                        setOpenPortal(!openPortal);
                    }}
                    role="combobox"
                    aria-haspopup="dialog"
                    aria-expanded={openPortal}
                    aria-controls="flex-dates-container"
                    clickTagCategory={isSearchPage ? 'Search' : 'Homepage'}
                    clickTagAction={
                        openPortal ? 'Hide Dates Overlay' : 'Show Dates Overlay'
                    }
                    clickTagLabel="Flexible Booking"
                >
                    <div
                        className={cx('flex-dates-input-icon', {
                            'hide-icon': hideCalendarIcon,
                        })}
                    >
                        <Icon iconName="calendar" />
                    </div>{' '}
                    {buttonLabel}
                </Button>
                {hasFilters && showClear && (
                    <Button
                        id="flex-dates-input__clear"
                        className="flex-dates-input__clear"
                        onClick={onClear}
                        appearance="subtle"
                        size="xs"
                        iconBeforeElement={<Icon iconName="close" />}
                        screenReaderTextBefore="Clear dates selected"
                    />
                )}
            </>
        );
    }
);

export default FlexDatesInput;
