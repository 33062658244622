/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { AppIconAppleStore, AppIconGooglePlay } from '../../Icons/App/AppIcons';

export function MobileAppButtons() {
    return (
        <>
            <a
                href="https://itunes.apple.com/us/app/recreation-gov/id1440487780?ls=1&mt=8"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Download on the App Store"
            >
                <AppIconAppleStore />
            </a>
            <a
                href="https://play.google.com/store/apps/details?id=com.bah.r1smobile&hl=en_US"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Download on the Google Play Store"
            >
                <AppIconGooglePlay />
            </a>
        </>
    );
}
