/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Stack,
    FauxImage,
    FauxUnavailableImage,
    Button,
    Text,
    Heading,
    DividerNew as Divider,
    Placeholder,
    PlaceholderText,
    Frame,
} from 'sarsaparilla';

export function OrderDetailsSummary({
    imageUrl,
    heading,
    parentName,
    children,
    onProceedToCartClick,
    isProceedToCartDisabled,
    isLoading,
    proceedToCartButtonText = 'Proceed to Cart',
    secondaryButtonText = 'Continue Shopping',
    onSecondaryButtonClick,
    isWaitingForServerResponse,
    secondaryButtonIsDisabled,
}) {
    if (isLoading) {
        return <OrderDetailSummaryPlaceholder />;
    }
    return (
        <div data-component="OrderDetailsSummary">
            <Box border="gray" background="white">
                {!imageUrl && <FauxUnavailableImage aspectRatio="21:9" />}
                {!!imageUrl && (
                    <FauxImage aspectRatio="21:9" src={imageUrl} altText={heading} />
                )}

                <div className="inner-padded">
                    <Stack>
                        <Stack space="xxs">
                            <Heading headingLevel={2} appearance="h6">
                                {heading}
                                <span className="rec-sr-only">Order Details Summary</span>
                            </Heading>
                            {parentName && (
                                <Text size="sm" color="gray">
                                    Part of {parentName}
                                </Text>
                            )}
                        </Stack>
                        <Divider />
                        {children}

                        <Stack space="xs">
                            <Button
                                isLoading={isWaitingForServerResponse}
                                onClick={onProceedToCartClick}
                                isDisabled={isProceedToCartDisabled}
                                data-testid="OrderDetailsSummary-cart-btn"
                            >
                                {proceedToCartButtonText}
                            </Button>
                            {!!onSecondaryButtonClick && (
                                <Button
                                    onClick={onSecondaryButtonClick}
                                    isDisabled={secondaryButtonIsDisabled}
                                    appearance="link"
                                >
                                    {secondaryButtonText}
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </div>
            </Box>
        </div>
    );
}

OrderDetailsSummary.propTypes = {
    onProceedToCartClick: PropTypes.func,
    onSecondaryButtonClick: PropTypes.func,
    heading: PropTypes.node,
    parentName: PropTypes.node,
    children: PropTypes.node,
    imageUrl: PropTypes.string,
    secondaryButtonText: PropTypes.string,
    isProceedToCartDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isWaitingForServerResponse: PropTypes.bool,
    proceedToCartButtonText: PropTypes.string,
    secondaryButtonIsDisabled: PropTypes.bool,
};

function OrderDetailSummaryPlaceholder() {
    return (
        <div data-component="OrderDetailsSummary">
            <Box border="gray" background="white">
                <Frame aspectRatio="21:9">
                    <Placeholder />
                </Frame>

                <div className="inner-padded">
                    <Stack>
                        <Stack space="xxs">
                            <PlaceholderText fontSize={16} width="80%" />
                            <PlaceholderText fontSize={11} width="50%" />
                        </Stack>

                        <Divider />

                        <PlaceholderText lines={4} />

                        <Stack space="xs">
                            <Placeholder height="4rem" />
                            <Placeholder height="4rem" />
                        </Stack>
                    </Stack>
                </div>
            </Box>
        </div>
    );
}
