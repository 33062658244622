/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * HomePageContainer.jsx
 * Created by michael bray on 10/4/17
 * Updated by Destin Frasier on 07/30/20
 * Updated by Aaron Luna on 01/26/22
 */

import React from 'react';
import { withCompatRouter } from 'sarsaparilla/legacy';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';

import {
    SignInModal,
    LoginActions,
    SearchActions,
    HelmetWrapper,
    Stack,
    FeatureFlag,
    TYPES,
    ContentBlock,
    Spacer,
    withLDConsumer,
} from 'sarsaparilla';

import { PlanYourTripPromo, Logo } from 'site-kit';

import { MerchandiseReel } from 'ui-merchandise/src/shared/MerchandiseReel';

import MapContainer from './MapContainer';

import { InspirationAndInformation } from './InspirationAndInformation';
import NewsletterContainer from './NewsletterContainer';
import DividerPromoContainer from './DividerPromoContainer';
import AppDividerPromoContainer from './AppDividerPromoContainer';
import TreePermitContainer from './TreePermitContainer';
import DiscoverContainer from './DiscoverContainer';
import UGCContainer from './UGCContainer';
import UGCClosedContainer from './UGCClosedContainer';
import HeroContainer from './HeroContainer';
import ExploreAllQuickPromos from './ExploreAllQuickPromos';

const combinedActions = {
    ...LoginActions,
    ...SearchActions,
};

export class HomePageContainer extends React.PureComponent {
    static propTypes = {
        router: TYPES.ROUTER,
        auth: PropTypes.shape({
            account: PropTypes.object,
            loginError: PropTypes.bool,
        }),
        account: PropTypes.any,
        hasLoginError: PropTypes.bool,
        login: PropTypes.func,
        loginAfterPasswordUpdated: PropTypes.func,
        magicLogin: PropTypes.func,
        csrSession: PropTypes.func,
        csrSessionWithUrl: PropTypes.func,
        submitSimpleSearch: PropTypes.func,
        showLoginModal: PropTypes.func,
        showCreateAccountModal: PropTypes.func,
        showResetModal: PropTypes.func,
        mainDomain: PropTypes.string,
        mainDomainName: PropTypes.string,
    };

    static defaultProps = {
        mainDomain: process.env.SITE_NAME,
        mainDomainName: process.env.SITE_ROOT,
    };

    constructor(props) {
        super(props);
        this.measurePage = throttle(this.measurePage.bind(this), 50);
    }

    state = {
        confirmModalOpen: false,
        isLoginModalOpen: false,
        isMobile: undefined,
    };

    componentDidMount() {
        const { csrSessionWithUrl, csrSession } = this.props;

        this.setState({
            isLoginModalOpen: this.props.router.location.pathname === '/mfa',
        });

        if (this.props.router.params.csrToken) {
            if (this.props.router.params?.newUrl) {
                csrSessionWithUrl(
                    this.props.router.params.csrToken,
                    atob(this.props.router.params.newUrl)
                );
            }
            csrSession(this.props.router.params.csrToken);
            this.redirectHome(true);
        }

        this.measurePage();
        window.addEventListener('resize', this.measurePage);
    }

    componentDidUpdate(prevProps) {
        const { account, hasLoginError, ldClient } = this.props;
        const { isLoginModalOpen } = this.state;

        if (
            account &&
            prevProps.account === null &&
            (isLoginModalOpen || this.props.router.location.pathname === '/magic-log-in')
        ) {
            // Check for magic-login-delete
            if (
                this.props.router.location.pathname === '/magic-log-in' &&
                (this.props.router.searchParams.get('delete') || false)
            ) {
                this.redirectAccountDelete();
                return;
            }

            // Successful login
            this.redirectHome();
            return;
        }

        if (
            this.props.router.location.pathname !== prevProps.router.location.pathname ||
            hasLoginError !== prevProps.hasLoginError ||
            (!prevProps.ldClient && ldClient !== prevProps.ldClient)
        ) {
            this.redirectToSpecialCaseURLs();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.measurePage);
    }

    getHasMfaEnabled = () => {
        const params = new URLSearchParams(this.props.router.location.search);
        const hasMfa = params.get('auth-required');
        return hasMfa;
    };

    showConfirmationModal = () => {
        const { showConfirmModal } = this.props;
        if (
            this.props.router.params.confirmToken ||
            this.props.router.params.resetToken
        ) {
            showConfirmModal();
            return true;
        }
        return false;
    };

    redirectToSpecialCaseURLs = async () => {
        const { account, hasLoginError, ldClient } = this.props;
        if (!ldClient) return;

        await ldClient.waitUntilReady();

        const hasConfirmModal = this.showConfirmationModal();

        if (hasConfirmModal) {
            return;
        }

        // Show login modal at '/login' and redirect to '/' if logged in
        if (this.props.router.location.pathname === '/log-in') {
            if (!account) {
                this.props.showLoginModal();
            } else {
                this.redirectHome();
            }
            return;
        }

        // Show signUp modal at '/sign-up' and redirect to '/' if logged in
        if (this.props.router.location.pathname === '/sign-up') {
            if (!account) {
                this.props.showCreateAccountModal();
            } else {
                this.redirectHome();
            }
            return;
        }

        // Show reset password modal at '/reset-password' and redirect to '/' if logged in
        if (this.props.router.location.pathname === '/reset-password') {
            if (!account) {
                this.props.showResetModal();
            } else {
                this.redirectHome();
            }
        }

        if (this.props.router.location.pathname === '/magic-log-in') {
            const magicID = this.props.router.searchParams.get('magic');
            const magicDelete = this.props.router.searchParams.get('delete');
            const hasMfa = this.getHasMfaEnabled();

            if (!account && !hasLoginError && magicID) {
                if (hasMfa) {
                    this.props.showMfaCodeModal();
                } else {
                    this.props.magicLogin(magicID);
                }
            } else if (magicDelete) {
                this.redirectAccountDelete();
            } else {
                this.redirectHome();
            }
        }
    };

    externalRedirect = (redirectURL, shouldForceHome = false) => {
        if (redirectURL !== '') {
            this.setState(
                {
                    confirmModalOpen: false,
                    isLoginModalOpen: false,
                },
                () => {
                    window.location.assign(redirectURL);
                }
            );
        } else {
            this.redirectHome(shouldForceHome);
        }
    };

    redirectHome = (shouldForceHome = false) => {
        this.setState(
            {
                confirmModalOpen: false,
                isLoginModalOpen: false,
            },
            () => {
                if (shouldForceHome) {
                    this.props.router.navigate('/', { replace: true });
                } else {
                    this.props.router.navigate('/');
                }
            }
        );
    };

    redirectAccountDelete = () => {
        this.setState(
            {
                confirmModalOpen: false,
                isLoginModalOpen: false,
            },
            () => {
                window.location.assign('/account/delete-account');
            }
        );
    };

    // Calculate & Update the State
    measurePage() {
        this.setState({ isMobile: window.innerWidth < 768 });
    }

    render() {
        const {
            mainDomainName,
            mainDomain,
            submitSimpleSearch,
            account,
            login,
            hasLoginError,
        } = this.props;
        const { isMobile, isLoginModalOpen } = this.state;
        const { showMerchandise } = process.env.HOMEPAGE_OPTIONS;

        return (
            <div role="main" className="nav-homepage-wrap">
                <HelmetWrapper
                    title="Bring Home a Story"
                    url={mainDomainName}
                    description={`${mainDomain} - Bring Home a Story`}
                    image={`${process.env.CDN}/img/hero-image.jpg`}
                />

                <h1 className="rec-sr-only">{process.env.SITE_NAME}</h1>
                <HeroContainer submitSimpleSearch={submitSimpleSearch} />

                <Stack space={['xxl', 'xxxl', 'xxxl']}>
                    {/* Show the mobile versions of the Explore All section + Promo */}
                    {isMobile && (
                        <>
                            <div className="promos-container">
                                <ExploreAllQuickPromos search={submitSimpleSearch} />
                            </div>

                            {/* Show/Hide the Mobile Tree Permits Promo */}
                            <FeatureFlag flag="treePermitHomepagePromo">
                                <ContentBlock>
                                    <TreePermitContainer />
                                </ContentBlock>
                            </FeatureFlag>

                            {/* Show/Hide the Mobile App Promo */}
                            <FeatureFlag flag="appHomepageDividerPromoPositionOne">
                                <ContentBlock>
                                    <AppDividerPromoContainer topSpacerSize="xxs" />
                                </ContentBlock>
                            </FeatureFlag>

                            {/* Show/Hide the ACTIVE Share Your Story Promo */}
                            <FeatureFlag flag="ugcHomepagePromoPositionOne">
                                <ContentBlock>
                                    <UGCContainer />
                                </ContentBlock>
                            </FeatureFlag>
                        </>
                    )}

                    <PlanYourTripPromo />

                    {/* Show the Promos on Desktop */}
                    {!isMobile && (
                        <>
                            {/* Show/Hide the Tree Permits Promo */}
                            <FeatureFlag flag="treePermitHomepagePromo">
                                <ContentBlock>
                                    <TreePermitContainer />
                                </ContentBlock>
                            </FeatureFlag>

                            {/* Show/Hide the Mobile App */}
                            <FeatureFlag flag="appHomepageDividerPromoPositionOne">
                                <ContentBlock>
                                    <AppDividerPromoContainer topSpacerSize="lg" />
                                </ContentBlock>
                            </FeatureFlag>

                            {/* Show/Hide the ACTIVE Share Your Story Promo */}
                            <FeatureFlag flag="ugcHomepagePromoPositionOne">
                                <ContentBlock>
                                    <UGCContainer />
                                </ContentBlock>
                            </FeatureFlag>
                        </>
                    )}

                    {isMobile === false && <MapContainer />}

                    {/* ONLY ONE OF THE THREE PROMOS BELOW SHOULD BE ON AT A TIME & Hidden on Mobile*/}
                    {!isMobile && (
                        <>
                            {/* Show/Hide the Mobile App Promo */}
                            <FeatureFlag flag="appHomepageDividerPromo">
                                <ContentBlock>
                                    <AppDividerPromoContainer />
                                </ContentBlock>
                            </FeatureFlag>

                            {/* Show/Hide the Passes Promo */}
                            <FeatureFlag flag="passesHomepageDividerPromo">
                                <ContentBlock>
                                    <DividerPromoContainer />
                                </ContentBlock>
                            </FeatureFlag>

                            {/* Show/Hide the Mobile App */}
                            <FeatureFlag flag="appHomepageDividerPromoPositionTwo">
                                <ContentBlock>
                                    <AppDividerPromoContainer topSpacerSize="lg" />
                                </ContentBlock>
                            </FeatureFlag>

                            {/* Show/Hide the ACTIVE Share Your Story Promo */}
                            <FeatureFlag flag="ugcHomepagePromo">
                                <ContentBlock>
                                    <UGCContainer />
                                </ContentBlock>
                            </FeatureFlag>

                            {/* Show/Hide the CLOSED Share Your Story Promo */}
                            <FeatureFlag flag="ugcClosedHomepagePromo">
                                <ContentBlock>
                                    <UGCClosedContainer />
                                </ContentBlock>
                            </FeatureFlag>
                        </>
                    )}

                    {showMerchandise && <MerchandiseReel />}

                    <InspirationAndInformation />

                    <ContentBlock>
                        <NewsletterContainer location={this.props.router.location} />
                    </ContentBlock>

                    {process.env.FEATURES?.discover ? <DiscoverContainer /> : null}
                </Stack>

                <Spacer size={['xxl', 'xxxl', 'xxxl']} />

                <SignInModal
                    logoElement={<Logo />}
                    isOpen={isLoginModalOpen && !account}
                    closeModal={this.redirectHome}
                    login={login}
                    hasError={hasLoginError}
                    isMfaValidated
                />
            </div>
        );
    }
}

export default withLDConsumer()(
    withCompatRouter(
        connect(
            (state) => ({
                account: get(state, 'auth.account', null),
                hasLoginError: get(state, 'auth.loginError', false),
            }),
            (dispatch) => bindActionCreators(combinedActions, dispatch)
        )(HomePageContainer)
    )
);
