/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import type { MenuSection, MenuSectionsType } from '../../../types/MenuSections';
// Used in MobileMenu.jsx
const navCategory = 'Global Navigation';

export const MENU_SECTIONS: MenuSectionsType = [
    {
        title: 'Camping & Lodging',
        links: [
            {
                ariaLabel: 'Tent-Camping',
                title: 'Tent-Camping',
                clickTagCategory: navCategory,
                clickTagAction: 'Tent-Camping',
                inventoryTypes: ['camping'],
                uiFilters: {
                    campsiteTypes: {
                        'campsite-type-standard': {
                            key: 'campsite-type-standard',
                            value: 'Standard',
                            filters: [
                                {
                                    attribute: 'campsite_type',
                                    filterValue: 'STANDARD ELECTRIC,STANDARD NONELECTRIC',
                                },
                            ],
                        },
                        'campsite-type-tent-only': {
                            key: 'campsite-type-tent-only',
                            value: 'Tent Only',
                            filters: [
                                {
                                    attribute: 'campsite_type',
                                    filterValue:
                                        'TENT ONLY ELECTRIC,TENT ONLY NONELECTRIC,GROUP TENT ONLY AREA NONELECTRIC',
                                },
                            ],
                        },
                    },
                },
            },
            {
                ariaLabel: 'RV/Trailer',
                title: 'RV/Trailer',
                clickTagCategory: navCategory,
                clickTagAction: 'RV/Trailer',
                inventoryTypes: ['camping'],
                uiFilters: {
                    campsiteEquipment: {
                        'campsite-equipment-rv': {
                            key: 'campsite-equipment-rv',
                            value: 'RV/Motorhome',
                            filters: [
                                {
                                    attribute: 'campsite_equipment_name',
                                    filterValue: 'RV,RV/Motorhome',
                                },
                            ],
                        },
                        'campsite-equipment-trailer': {
                            key: 'campsite-equipment-trailer',
                            value: 'Trailer',
                            filters: [
                                {
                                    attribute: 'campsite_equipment_name',
                                    filterValue: 'Trailer',
                                },
                            ],
                        },
                    },
                },
            },
            {
                ariaLabel: 'Cabins/Lookouts/Yurts',
                title: 'Cabins/Lookouts/Yurts',
                clickTagCategory: navCategory,
                clickTagAction: 'Cabins/Lookouts/Yurts',
                inventoryTypes: ['camping'],
                uiFilters: {
                    campsiteTypes: {
                        'campsite-type-cabin': {
                            key: 'campsite-type-cabin',
                            value: 'Cabin',
                            filters: [
                                {
                                    attribute: 'campsite_type',
                                    filterValue: 'CABIN ELECTRIC,CABIN NONELECTRIC',
                                },
                            ],
                        },
                        'campsite-type-lookout': {
                            key: 'campsite-type-lookout',
                            value: 'Lookout',
                            filters: [
                                {
                                    attribute: 'campsite_type',
                                    filterValue: 'LOOKOUT',
                                },
                            ],
                        },
                        'campsite-type-yurts': {
                            key: 'campsite-type-yurts',
                            value: 'Yurts',
                            filters: [
                                {
                                    attribute: 'campsite_type',
                                    filterValue: 'YURT',
                                },
                            ],
                        },
                    },
                },
            },
            {
                ariaLabel: 'Group Sites',
                title: 'Group Sites',
                clickTagCategory: navCategory,
                clickTagAction: 'Group Sites',
                inventoryTypes: ['camping'],
                uiFilters: {
                    campsiteTypes: {
                        'campsite-type-group-standard': {
                            key: 'campsite-type-group-standard',
                            value: 'Group Standard',
                            filters: [
                                {
                                    attribute: 'campsite_type',
                                    filterValue:
                                        'GROUP STANDARD ELECTRIC,GROUP STANDARD NONELECTRIC',
                                },
                            ],
                        },
                    },
                },
            },
        ],
    },
    {
        title: 'Activities',
        links: [
            {
                ariaLabel: 'Permits',
                title: 'Permits',
                clickTagCategory: navCategory,
                clickTagAction: 'Permits',
                inventoryTypes: ['permits'],
                uiFilters: undefined,
            },
            {
                ariaLabel: 'Activity Passes',
                title: 'Activity Passes',
                clickTagCategory: navCategory,
                clickTagAction: 'Activity Passes',
                inventoryTypes: ['activitypass'],
                uiFilters: undefined,
            },
            {
                ariaLabel: 'Tickets and Tours',
                title: 'Tickets and Tours',
                clickTagCategory: navCategory,
                clickTagAction: 'Tickets and Tours',
                inventoryTypes: ['tours'],
                uiFilters: undefined,
            },
            {
                ariaLabel: 'Lotteries',
                title: 'Lotteries',
                clickTagCategory: navCategory,
                clickTagAction: 'Lotteries',
                url: '/lottery/available',
                uiFilters: undefined,
            },
            {
                ariaLabel: 'Christmas Trees (Seasonal)',
                title: 'Christmas Trees (Seasonal)',
                clickTagCategory: navCategory,
                clickTagAction: 'Christmas Trees (Seasonal)',
                inventoryTypes: ['treepermit'],
                uiFilters: undefined,
            },
        ],
    },
    {
        title: 'Visit for the Day',
        links: [
            {
                url: '/pass',
                ariaLabel: 'Passes',
                title: 'Passes',
                target: '_self',
                clickTagCategory: navCategory,
                clickTagAction: 'Passes',
            },
            {
                ariaLabel: 'Timed Entry / Vehicle Reservations',
                title: 'Timed Entry / Vehicle Reservations',
                clickTagCategory: navCategory,
                clickTagAction: 'Timed Entry / Vehicle Reservations',
                inventoryTypes: ['tours'],
                uiFilters: undefined,
            },
            {
                ariaLabel: 'Day Use',
                title: 'Day Use',
                clickTagCategory: navCategory,
                clickTagAction: 'Day Use',
                inventoryTypes: ['dayuse', 'venuereservations'],
                uiFilters: undefined,
            },
        ],
    },
    {
        title: 'Get Help',
        links: [
            {
                url: process.env.SN_KB_EXT_URL,
                ariaLabel: 'Help Center',
                title: 'Help Center',
                target: '_blank',
                clickTagCategory: navCategory,
                clickTagAction: 'Help Center',
            },
            {
                url: '/articles',
                ariaLabel: 'Tips and Tricks',
                title: 'Tips and Tricks',
                target: '_self',
                clickTagCategory: navCategory,
                clickTagAction: 'Tips and Tricks',
            },
            {
                url: '/trips/new',
                ariaLabel: 'Trip Builder',
                title: 'Trip Builder',
                target: '_self',
                clickTagCategory: navCategory,
                clickTagAction: 'Trip Builder',
            },
            {
                url: '/rules-reservation-policies',
                ariaLabel: 'Rules and Reservation Policies Center',
                title: 'Rules and Reservation Policies Center',
                target: '_self',
                clickTagCategory: navCategory,
                clickTagAction: 'Rules and Reservation Policies Center',
            },
            {
                // eslint-disable-next-line dot-notation
                url: `${process.env.SN_KB_EXT_URL}?id=external_contact_us`,
                ariaLabel: 'Contact Us',
                title: 'Contact Us',
                target: '_blank',
                clickTagCategory: navCategory,
                clickTagAction: 'Contact Us',
            },
        ],
    },
    {
        title: 'About Us',
        links: [
            {
                url: '/about-us',
                ariaLabel: 'About Recreation.gov',
                title: 'About Recreation.gov',
                target: '_self',
                clickTagCategory: navCategory,
                clickTagAction: 'About Recreation.gov',
            },
            {
                url: '/accessibility',
                ariaLabel: 'Accessibility',
                title: 'Accessibility',
                target: '_self',
                clickTagCategory: navCategory,
                clickTagAction: 'Accessibility',
            },
        ],
    },
];

export const CAMPING_SECTION: MenuSection = {
    title: 'Camping & Lodging',
    links: [
        {
            ariaLabel: 'RV/Motorhome/Trailer Camping',
            title: 'RV/Motorhome/Trailer Camping',
            clickTagCategory: navCategory,
            clickTagAction: 'RV/Motorhome/Trailer Camping',
            inventoryTypes: ['camping'],
            uiFilters: {
                campsiteEquipment: {
                    'campsite-equipment-rv': {
                        key: 'campsite-equipment-rv',
                        value: 'RV/Motorhome',
                        filters: [
                            {
                                attribute: 'campsite_equipment_name',
                                filterValue: 'RV,RV/Motorhome',
                            },
                        ],
                    },
                    'campsite-equipment-trailer': {
                        key: 'campsite-equipment-trailer',
                        value: 'Trailer',
                        filters: [
                            {
                                attribute: 'campsite_equipment_name',
                                filterValue: 'Trailer',
                            },
                        ],
                    },
                },
            },
        },
        {
            ariaLabel: 'Tent Camping',
            title: 'Tent Camping',
            clickTagCategory: navCategory,
            clickTagAction: 'Tent Camping',
            inventoryTypes: ['camping'],
            uiFilters: {
                campsiteTypes: {
                    'campsite-type-standard': {
                        key: 'campsite-type-standard',
                        value: 'Standard',
                        filters: [
                            {
                                attribute: 'campsite_type',
                                filterValue: 'STANDARD ELECTRIC,STANDARD NONELECTRIC',
                            },
                        ],
                    },
                    'campsite-type-tent-only': {
                        key: 'campsite-type-tent-only',
                        value: 'Tent Only',
                        filters: [
                            {
                                attribute: 'campsite_type',
                                filterValue:
                                    'TENT ONLY ELECTRIC,TENT ONLY NONELECTRIC,GROUP TENT ONLY AREA NONELECTRIC',
                            },
                        ],
                    },
                },
            },
        },
        {
            ariaLabel: 'Lodging (Cabins/Yurts/Lookouts)',
            title: 'Lodging (Cabins/Yurts/Lookouts)',
            clickTagCategory: navCategory,
            clickTagAction: 'Lodging (Cabins/Yurts/Lookouts)',
            inventoryTypes: ['camping'],
            uiFilters: {
                campsiteTypes: {
                    'campsite-type-cabin': {
                        key: 'campsite-type-cabin',
                        value: 'Cabin',
                        filters: [
                            {
                                attribute: 'campsite_type',
                                filterValue: 'CABIN ELECTRIC,CABIN NONELECTRIC',
                            },
                        ],
                    },
                    'campsite-type-lookout': {
                        key: 'campsite-type-lookout',
                        value: 'Lookout',
                        filters: [
                            {
                                attribute: 'campsite_type',
                                filterValue: 'LOOKOUT',
                            },
                        ],
                    },
                    'campsite-type-yurts': {
                        key: 'campsite-type-yurts',
                        value: 'Yurts',
                        filters: [
                            {
                                attribute: 'campsite_type',
                                filterValue: 'YURT',
                            },
                        ],
                    },
                },
            },
        },
    ],
};

// cSpell:ignore activitypass, treepermit,dayuse, venuereservations
