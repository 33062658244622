/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import { NoMapboxGlNotice, Spinner } from 'sarsaparilla';

function LoadingSpinner({ mapRef, className, isMapLoaded }) {
    return (
        <div ref={mapRef} className={className || 'mapbox-basic-map'}>
            {!isMapLoaded && <Spinner />}
            {!mapboxgl.supported() && <NoMapboxGlNotice />}
        </div>
    );
}

LoadingSpinner.propTypes = {
    className: PropTypes.string,
    mapRef: PropTypes.func,
    isMapLoaded: PropTypes.bool,
};

export default LoadingSpinner;
