/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import { useParams } from 'react-router-dom-v5-compat';

import {
    Spinner,
    Alert,
    ContentBlock,
    BackToTop,
    ContentContainerBodyShift,
    FlexRow,
} from 'sarsaparilla';

import { useFetchArticleByIdQuery } from '../../redux';

import ArticleJournalBody from '../../components/articles/article/articleBody/ArticleJournalBody';
import ArticleListBody from '../../components/articles/article/articleBody/ArticleListBody';
import ArticleLocationBody from '../../components/articles/article/articleBody/ArticleLocationBody';
import ArticleHero from '../../components/articles/article/ArticleHero';
import ArticleLeftRail from '../../components/articles/article/ArticleLeftRail';

type ContentType = 'journal' | 'list' | 'location-spotlight';
type BreadCrumb = {
    title: string;
    url?: string;
};

export function ArticlePage() {
    const { contentType, id } = useParams<{
        contentType: ContentType;
        id: string;
    }>();

    const { data: articleData, isLoading, isError } = useFetchArticleByIdQuery(id);
    const article = articleData?.result?.records?.[0]?.[0];

    const articleTitle = React.useMemo(() => {
        if (!article?.article_title) {
            return '';
        }

        const doc = new DOMParser().parseFromString(article.article_title, 'text/html');
        return doc?.body?.textContent || '';
    }, [article]);

    const articleBody = React.useMemo(() => {
        if (!article) {
            return null;
        }

        const paths: BreadCrumb[] = [
            { title: 'Home', url: '/' },
            { title: 'Articles', url: '/articles' },
            { title: articleTitle },
        ];

        if (contentType === 'journal') {
            return <ArticleJournalBody article={article} breadCrumbPath={paths} />;
        }

        if (contentType === 'list') {
            return <ArticleListBody article={article} breadCrumbPath={paths} />;
        }

        if (contentType === 'location-spotlight') {
            return <ArticleLocationBody article={article} breadCrumbPath={paths} />;
        }

        return null;
    }, [article, articleTitle, contentType]);

    if (isLoading) {
        return (
            <main>
                <Spinner />
            </main>
        );
    }

    if (isError || !articleData || !article || !articleBody) {
        return (
            <main>
                <ContentBlock>
                    <Alert type="error">
                        Sorry, the article could not be loaded. Please try again later.
                    </Alert>
                </ContentBlock>
            </main>
        );
    }

    return (
        <div className="nav-article-section-wrap">
            <ArticleHero article={article} articleTitle={articleTitle} />
            <ContentContainerBodyShift className="mt-0">
                <FlexRow>
                    {articleBody}

                    <ArticleLeftRail article={article} contentType={contentType} />
                </FlexRow>
            </ContentContainerBodyShift>

            <BackToTop />
        </div>
    );
}
