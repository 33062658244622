/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * Created by michaelbray on 7/2/18.
 */

import React from 'react';
import PropTypes from 'prop-types';

import {
    Breadcrumbs,
    DisplayHtmlContent,
    Heading,
    FlexRow,
    FlexCol,
    ShareBtn,
    Button,
} from 'sarsaparilla';

import { BREADCRUMB, LIST } from '../../../../constants/propTypes';
import { generateContentImage } from '../../../../utils/articlesHelper';
import ArticleSource from '../ArticleSource';

export class ArticleListBody extends React.PureComponent {
    static propTypes = {
        breadCrumbPath: PropTypes.arrayOf(BREADCRUMB),
        article: LIST,
    };

    render() {
        const { article } = this.props;

        return (
            <FlexCol md={8} mdOrder={2}>
                <article className="nav-article-body" role="main">
                    <ArticleSource
                        articleLocationSource={article.hero_image_location_and_source}
                    />

                    <Button
                        appearance="tertiary"
                        size="sm"
                        className="mb-1 article-skip-to-side p-1"
                        href="#nav-article-left-menu"
                    >
                        Skip to side menu
                    </Button>

                    <FlexRow alignItems="center" className="mb-3">
                        <FlexCol>
                            <Breadcrumbs path={this.props.breadCrumbPath} />
                        </FlexCol>

                        <FlexCol xs={12} lg="variable" className="rec-share-btn-wrap">
                            <ShareBtn />
                        </FlexCol>
                    </FlexRow>

                    <Heading headingLevel={2} appearance="h2">
                        <DisplayHtmlContent html={article.article_subhead} />
                    </Heading>

                    <DisplayHtmlContent html={article.article_overview} />

                    {article.list_item?.map((listItem, index) => (
                        <div key={index} className="nav-article-list">
                            <div className="nav-article-list-name">
                                {listItem.list_item_title}
                            </div>
                            <div className="nav-article-list-location">
                                {listItem.list_item_location}
                            </div>
                            <figure className="nav-article-list-image">
                                {generateContentImage({
                                    url: listItem.list_image_inventory_url,
                                    img: listItem.list_image,
                                    altText: listItem.list_image_alt_text,
                                })}
                                <figcaption>
                                    {listItem.list_image_location_and_source}
                                </figcaption>
                            </figure>
                            <DisplayHtmlContent html={listItem.list_item_description} />
                        </div>
                    ))}
                </article>
            </FlexCol>
        );
    }
}

export default ArticleListBody;
