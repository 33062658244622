/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { Children } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import {
    Button,
    ButtonGroup,
    Box,
    Icon,
    StyledModal,
    ModalActions,
    Inline,
    formatNumber,
    pluralize,
    Heading,
    Text,
} from 'sarsaparilla';

import FacilityFilterBarModalTabButton from './FacilityFilterBarModalTabButton';

export default function FacilityFilterBarModal({
    dispatch,
    children,
    isOpen,
    onRequestClose,
    activeIndex,
    numResults,
    showButtonLoading,
    resultsWordSingular = 'Result',
    resultsWordPlural = 'Results',
    resultsText = '',
    modalBarTitle,
    modalBarDescription,
    modalSize = 'xxl',
    secondaryButton = null,
}) {
    let clearButton;
    const resultsWord = pluralize(numResults, resultsWordSingular, resultsWordPlural);

    const tabButtons = Children.map(children, (child, index) => {
        const clearButtonLabel =
            child.props.clearButtonLabel || `Clear ${child.props.label}`;

        if (activeIndex === index && child.props.onClearClick && child.props.value) {
            clearButton = (
                <Button
                    appearance="link"
                    iconBeforeElement={<Icon iconName="close-circle" />}
                    onClick={child.props.onClearClick}
                >
                    {clearButtonLabel}
                </Button>
            );
        }

        // Don't show tab bar with there's only 1 filter
        if (!Array.isArray(children) || !children?.length) {
            return null;
        }

        return (
            <FacilityFilterBarModalTabButton
                id={`ffb-modal-button-${index}`}
                onClick={() => dispatch({ type: 'switchToIndex', index })}
                isActive={activeIndex === index}
                label={child.props.label}
                value={child.props.value}
                placeholder={child.props.placeholder}
                onClearClick={child.props.onClearClick}
                clearButtonLabel={clearButtonLabel}
            />
        );
    });

    // If there's only 1 filter then children is an object
    const activeTabElement = Array.isArray(children) ? children[activeIndex] : children;
    let buttonText = isEmpty(resultsText)
        ? `Show ${formatNumber(numResults, 0)} ${resultsWord}`
        : resultsText;
    if (activeTabElement && activeTabElement.props.resultsText) {
        buttonText = activeTabElement.props.resultsText;
    }

    if (!isOpen) {
        return null;
    }

    return (
        <StyledModal
            id="shared-filter-options-modal"
            heading={<div className="rec-sr-only">Search Options</div>}
            size={modalSize}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            shouldActionsStickInMobile
        >
            <Box paddingY="lg" className={`child-count-${children.length}`}>
                <Inline space="sm" alignX="center" data-shared-ffb-modal-tab-bar>
                    {tabButtons}
                </Inline>

                {activeTabElement && (
                    <div
                        className="shared-ffb-modal-panel"
                        role="region"
                        aria-labelledby={`ffb-modal-button-${activeIndex}`}
                    >
                        <div
                            className={cx(
                                'shared-ffb-modal-panel-sizer',
                                activeTabElement.props.panelWidth
                            )}
                        >
                            {activeTabElement}
                        </div>
                    </div>
                )}
            </Box>

            <ModalActions>
                <div className="description-content">
                    {modalBarTitle && (
                        <Heading headingLevel={1} appearance="h5">
                            {modalBarTitle}
                        </Heading>
                    )}
                    <Text color="gray">{modalBarDescription}</Text>
                </div>
                <ButtonGroup>
                    {clearButton}
                    {secondaryButton}
                    <Button
                        onClick={onRequestClose}
                        isDisabled={!!activeTabElement.props.isResultButtonDisabled}
                        isLoading={showButtonLoading}
                    >
                        {buttonText}
                    </Button>
                </ButtonGroup>
            </ModalActions>
        </StyledModal>
    );
}

FacilityFilterBarModal.propTypes = {
    dispatch: PropTypes.func,
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    numResults: PropTypes.number,
    activeIndex: PropTypes.number,
    resultsWordSingular: PropTypes.string,
    resultsWordPlural: PropTypes.string,
    resultsText: PropTypes.string,
    modalBarTitle: PropTypes.string,
    modalBarDescription: PropTypes.string,
    modalSize: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl']),
    showButtonLoading: PropTypes.bool,
    secondaryButton: PropTypes.element,
};
