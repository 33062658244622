/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect } from 'react';
import {
    Select,
    Carousel,
    Text,
    Icon,
    Button,
    ButtonGroup,
    Stack,
    Alert,
    pluralize,
} from 'sarsaparilla';
import cx from 'classnames';
import { FiltersProps } from './FlexDatesMain';
import { MonthsOptionsProps } from './FlexDatesInput';

interface CarouselTabProps {
    filters: FiltersProps;
    isMobile: boolean;
    setError: React.Dispatch<React.SetStateAction<string>>;
    onClear?: () => void;
    onApplyFilters?: () => void;
    error: string;
    setChecked: React.Dispatch<React.SetStateAction<Array<string>>>;
    setDuration: React.Dispatch<React.SetStateAction<string>>;
    checked: Array<string>;
    duration: string;
    limit?: number;
    monthsOptions: Array<MonthsOptionsProps>;
}

function CarouselTab({
    onApplyFilters,
    filters,
    isMobile,
    monthsOptions,
    setChecked,
    checked,
    duration,
    onClear,
    setDuration,
    error,
    setError,
    limit,
}: CarouselTabProps) {
    const handleInitializeFilters = React.useCallback(() => {
        if (filters.months) {
            setChecked(filters.months);
        }
        if (filters.duration) {
            setDuration(filters.duration);
        }
        if (!filters.duration) {
            setDuration('1');
        }
        if (!filters.months || filters?.months?.length === 0) {
            setChecked([]);
        }
    }, [filters, setChecked, setDuration]);

    useEffect(() => {
        handleInitializeFilters();
    }, [handleInitializeFilters]);

    useEffect(() => {
        if (error && checked.length > 0) {
            setError('');
        }
    }, [checked, error, setError]);

    const handleChecked = (value: string) => {
        const alreadyChecked = checked?.find((item) => item === value);
        if (alreadyChecked) {
            setChecked(checked.filter((item) => item !== value));
            return;
        }
        setChecked([...checked, value]);
    };

    const handleClear = () => {
        setError('');
        if (onClear) {
            onClear();
        }
    };

    const daysOptions = [...new Array(14).keys()].map((item) => ({
        label: item + 1,
        value: String(item + 1),
    }));

    // Find the location of the page for GA
    const isSearchPage = window.location.pathname.includes('search');

    const isDisabled = limit && checked.length >= limit;

    return (
        <div className="flex-dates-carousel">
            <Stack space="sm">
                <div className="flex-dates-select pt-1">
                    <Text fontWeight="semibold" size="xl" align="center">
                        Stay for{' '}
                    </Text>
                    <Select
                        id="flex-dates-select-component"
                        className="flex-dates-select-component"
                        isLabelVisible={false}
                        label="Select a number of nights in:"
                        aria-label="Choose number of nights for your stay"
                        value={duration}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                            setDuration(e.target.value)
                        }
                        options={daysOptions}
                    />

                    <Text fontWeight="semibold" size="xl" align="center">
                        {' '}
                        Nights in:
                    </Text>
                </div>
                {error && !isMobile && (
                    <Alert type="error" shouldFocusOnMount aria-label={error}>
                        {error}
                    </Alert>
                )}
                <div
                    className={cx('flex-dates-carousel-container', {
                        desktop: !isMobile,
                    })}
                >
                    {limit && (
                        <Text align="center" className="pb-2">
                            Select up to {limit} months:
                        </Text>
                    )}
                    <Carousel
                        className="carousel"
                        slideTargetWidth={isMobile ? 130 : 110}
                    >
                        {monthsOptions.map((item) => {
                            const isChecked = checked.includes(item?.value);
                            const GATagAction = isChecked ? 'Deselected' : 'Selected';
                            return (
                                <Button
                                    isUnstyled
                                    key={item.value}
                                    role="checkbox"
                                    onClick={() => handleChecked(item.value)}
                                    aria-label={`${item.month}, ${item.year}`}
                                    aria-checked={isChecked}
                                    clickTagCategory={
                                        isSearchPage ? 'Search' : 'Homepage'
                                    }
                                    clickTagAction={`${GATagAction} - ${item.month}, ${item.year}`}
                                    clickTagLabel="Flexible Booking"
                                    isDisabled={(isDisabled && !isChecked) || false}
                                    className={cx('flex-dates-carousel-item', {
                                        selected: isChecked,
                                        desktop: !isMobile,
                                        disabled: isDisabled && !isChecked,
                                    })}
                                >
                                    <div>
                                        <Icon
                                            iconName={
                                                isChecked
                                                    ? 'calendar-available'
                                                    : 'calendar'
                                            }
                                            size="lg"
                                            className={cx('flex-carousel-icon', {
                                                selected: isChecked,
                                            })}
                                        />
                                        <Text
                                            className="flex-carousel-month"
                                            size="lg"
                                            fontWeight="semibold"
                                        >
                                            {item.month}
                                        </Text>
                                        <Text>{item.year}</Text>
                                    </div>
                                </Button>
                            );
                        })}
                    </Carousel>
                </div>
                {!isMobile && (
                    <ButtonGroup align="right">
                        <Button
                            onClick={handleClear}
                            appearance="tertiary"
                            clickTagCategory={isSearchPage ? 'Search' : 'Homepage'}
                            clickTagAction="Clear Selection"
                            clickTagLabel="Flexible Booking"
                        >
                            Clear Selection
                        </Button>
                        <Button
                            onClick={onApplyFilters}
                            clickTagCategory={isSearchPage ? 'Search' : 'Homepage'}
                            clickTagAction={`${duration} ${pluralize(
                                Number(duration),
                                'Day',
                                'Days'
                            )} Within ${checked.length} Selected ${pluralize(
                                Number(checked.length),
                                'Month',
                                'Months'
                            )}`}
                            clickTagLabel="Flexible Booking"
                        >
                            Apply Selection
                        </Button>
                    </ButtonGroup>
                )}
            </Stack>
        </div>
    );
}

export default CarouselTab;
