/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect, useState } from 'react';
import {
    Button,
    ButtonGroup,
    RadioPill,
    FlexRow,
    Text,
    Alert,
    FlexCol,
    Inline,
    useFlags,
    RangeCalendar,
    DatePicker,
    DateRangePicker,
    DateRangePickerOnChange,
    localToday,
} from 'sarsaparilla';

import cx from 'classnames';
import { CalendarDate } from '@internationalized/date';
import { DateValue } from 'react-aria';
import { searchDate } from 'ui-search/src/utils/dateUtils';
import { FiltersProps } from './FlexDatesMain';

function Icon() {
    return (
        <FlexCol className="pb-half">
            <div className="plus-less-icon">+</div>
            <div className="plus-less-icon">_</div>
        </FlexCol>
    );
}

function RangeInputCount({
    value,
    relative,
    index,
    checked,
    isMobile,
}: {
    isMobile?: boolean;
    value: string;
    relative?: boolean;
    checked?: boolean;
    index?: number;
}) {
    return (
        <div key={value} className={cx('range-input-count', { relative })}>
            <Text
                className={cx('range-input-count-text', { checked })}
                fontWeight="semibold"
                size={isMobile ? 'xs' : 'sm'}
            >
                <Icon /> {isMobile && <div className="pr-half" />} {value}{' '}
                {index === 0 ? 'day' : 'days'}
            </Text>
        </div>
    );
}

interface RadioGroupProps {
    values: Array<{ label: React.ReactNode; value: string }>;
    isChecked: string;
    // should work on mouse click or keyboard enter
    onClick: (
        v: React.MouseEvent<HTMLInputElement, MouseEvent> | React.KeyboardEvent
    ) => void;
}

function RadioGroup({ values, onClick, isChecked }: RadioGroupProps) {
    return (
        <Inline space="xs">
            {values.map((item) => (
                <RadioPill
                    key={item.value}
                    className="calendar-flex-pills"
                    onClick={onClick}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            onClick(e);
                        }
                    }}
                    isChecked={isChecked === item.value}
                    value={item.value}
                    aria-label={`plus or minus ${item.value} ${
                        item.value === '1' ? 'day' : 'days'
                    }`}
                >
                    {item.label}
                </RadioPill>
            ))}
        </Inline>
    );
}

interface DatesProps {
    startDate?: CalendarDate | null;
    endDate?: CalendarDate | null;
}

export interface CalendarTabProps {
    filters: FiltersProps;
    isMobile: boolean;
    setError: React.Dispatch<React.SetStateAction<string>>;
    onClear?: () => void;
    onApplyFilters?: () => void;
    dates: DatesProps;
    error: string;
    isSearchPage?: boolean;
    setDates: React.Dispatch<React.SetStateAction<object>>;
    setStartRange: React.Dispatch<React.SetStateAction<string>>;
    startRange: string;
    setEndRange: React.Dispatch<React.SetStateAction<string>>;
    endRange: string;
    generateGaProps: () => {
        clickTagCategory: string;
        clickTagAction: string;
        clickTagLabel: string;
    };
}

function CalendarTab({
    onApplyFilters,
    filters,
    isMobile,
    dates,
    setDates,
    error,
    isSearchPage,
    setError,
    onClear,
    setStartRange,
    startRange,
    setEndRange,
    endRange,
    generateGaProps,
}: CalendarTabProps) {
    const [initialVisibleMonth, setInitialVisibleMonth] = useState<
        string | CalendarDate
    >();

    const { useFlexRangeDates } = useFlags();
    const rangeValues = ['1', '2', '3', '7'];
    const rangeOptionsStart = rangeValues.map((item, index) => ({
        label: (
            <RangeInputCount
                relative
                value={item}
                index={index}
                checked={startRange === item}
                isMobile={isMobile}
            />
        ),
        value: item,
    }));
    const rangeOptionsEnd = rangeValues.map((item, index) => ({
        label: (
            <RangeInputCount
                relative
                value={item}
                index={index}
                checked={endRange === item}
                isMobile={isMobile}
            />
        ),
        value: item,
    }));

    const handleClearListener = (v: string) => {
        if (isMobile) {
            setStartRange('');
            setEndRange('');
        } else if (v === 'end') {
            setEndRange('');
        } else {
            setStartRange('');
        }
    };

    const clearInputClass = '[class^="SingleDatePickerInput_clearDate"]';
    const mobileClearInputClass = '[class^="DateRangePickerInput_clearDates"]';

    useEffect(() => {
        const classValue = isMobile ? mobileClearInputClass : clearInputClass;
        const elements = document.querySelectorAll(classValue);
        const startClear = elements[0];
        const endClear = elements[1];
        if (startClear) {
            startClear.addEventListener('click', () => handleClearListener('start'));
        }
        if (endClear) {
            endClear.addEventListener('click', () => handleClearListener('end'));
        }
    });

    const handleInitializeFilters = React.useCallback(() => {
        if (filters.startDate && filters.endDate) {
            setInitialVisibleMonth(filters?.startDate);
            setDates(filters);
            if (filters.startRange) {
                setStartRange(filters.startRange);
            }
            if (filters.endRange) {
                setEndRange(filters.endRange);
            }
        } else {
            const today = searchDate(new Date());
            setDates({ startDate: null, endDate: null });
            setInitialVisibleMonth(today);
        }
    }, [filters, setStartRange, setInitialVisibleMonth, setDates, setEndRange]);

    useEffect(() => {
        handleInitializeFilters();
    }, [handleInitializeFilters]);

    const handleClear = () => {
        setError('');
        if (onClear) {
            onClear();
        }
    };
    /** Allow for user to select and unselect radio button range */
    const handleRangeChange = (v: string, range: string) => {
        setError('');
        if (range === 'mobile') {
            const value = v === startRange || v === endRange ? '' : v;
            setEndRange(value);
            setStartRange(value);
        } else if (range === 'start') {
            const value = v === startRange ? '' : v;
            setStartRange(value);
        } else {
            const value = v === endRange ? '' : v;
            setEndRange(value);
        }
    };

    const gaProps = generateGaProps();

    const isDateUnavailable = (day: DateValue) => {
        if (day?.compare) {
            return day.compare(localToday) < 0;
        }
        return false;
    };

    const onDateRangeChange: DateRangePickerOnChange = (v) => {
        setError('');
        setDates({
            startDate: v?.start,
            endDate: v?.end,
        });
    };

    const getRangeDates = () => {
        if (dates.endDate && dates.startDate) {
            return {
                start: searchDate(dates.startDate),
                end: searchDate(dates.endDate),
            };
        }
        return null;
    };

    return (
        <div className={cx('flex-dates-calendar', { desktop: !isMobile })}>
            {initialVisibleMonth && (
                <>
                    {!isMobile && (
                        <div
                            className={cx('flex-dates-calendar-input my-2', {
                                desktop: !isMobile,
                            })}
                        >
                            <div
                                className={cx('flex-dates-input-container', {
                                    desktop: !isMobile,
                                })}
                            >
                                <DatePicker
                                    label="Check In date"
                                    hasCalendar={false}
                                    isDateUnavailable={isDateUnavailable}
                                    onChange={(v) => {
                                        setError('');
                                        setDates({
                                            ...dates,
                                            startDate: v,
                                        });
                                    }}
                                    value={
                                        dates?.startDate
                                            ? searchDate(dates?.startDate)
                                            : null
                                    }
                                />

                                {startRange && (
                                    <RangeInputCount
                                        value={startRange}
                                        index={startRange === '1' ? 0 : 1}
                                    />
                                )}
                            </div>
                            <div className="flex-dates-input-container">
                                <DatePicker
                                    label="Check Out date"
                                    hasCalendar={false}
                                    onChange={(v) => {
                                        setError('');
                                        setDates({
                                            ...dates,
                                            endDate: v,
                                        });
                                    }}
                                    isDateUnavailable={isDateUnavailable}
                                    value={
                                        dates?.endDate ? searchDate(dates?.endDate) : null
                                    }
                                />

                                {endRange && <RangeInputCount value={endRange} />}
                            </div>
                        </div>
                    )}
                    {isMobile && (
                        <div className="flex-dates-calendar-input mt-2 mb-2">
                            <div className="flex-input-text">
                                <DateRangePicker
                                    label="Check In and Check Out dates"
                                    isLabelVisible={false}
                                    hasCalendar={false}
                                    value={getRangeDates()}
                                    isDateUnavailable={isDateUnavailable}
                                    onChange={onDateRangeChange}
                                />
                            </div>
                        </div>
                    )}
                    {error && !isMobile && (
                        <Alert
                            className="mt-2 mb-2"
                            role="alert"
                            type="error"
                            aria-label={error}
                            shouldFocusOnMount
                        >
                            {error}
                        </Alert>
                    )}

                    <div className="calendar-wrapper mb-2" id="calendar-wrapper">
                        <RangeCalendar
                            numVisibleMonths={isMobile ? 1 : 2}
                            isDateUnavailable={isDateUnavailable}
                            value={getRangeDates()}
                            onChange={onDateRangeChange}
                        />
                    </div>
                    {!isMobile && useFlexRangeDates && (
                        <div>
                            <FlexRow alignItems="center" className="mt-1 mb-2">
                                <FlexCol>
                                    <RadioGroup
                                        onClick={(e) =>
                                            handleRangeChange(
                                                (e.target as HTMLInputElement).value,
                                                'start'
                                            )
                                        }
                                        values={rangeOptionsStart}
                                        isChecked={startRange}
                                    />
                                </FlexCol>
                                <FlexCol>
                                    <RadioGroup
                                        onClick={(e) =>
                                            handleRangeChange(
                                                (e.target as HTMLInputElement).value,
                                                'end'
                                            )
                                        }
                                        values={rangeOptionsEnd}
                                        isChecked={endRange}
                                    />
                                </FlexCol>
                            </FlexRow>
                        </div>
                    )}
                    {isMobile && useFlexRangeDates && (
                        <FlexRow
                            alignItems="center"
                            justifyContent="center"
                            className="mt-1 mb-2"
                        >
                            <RadioGroup
                                // @ts-ignore
                                onClick={(e) =>
                                    handleRangeChange(
                                        (e.target as HTMLInputElement).value,
                                        'mobile'
                                    )
                                }
                                values={rangeOptionsEnd}
                                isChecked={startRange || endRange}
                            />
                        </FlexRow>
                    )}
                    {!isMobile && (
                        <ButtonGroup align="right" className="mt-2">
                            <Button
                                onClick={handleClear}
                                appearance="tertiary"
                                clickTagCategory={isSearchPage ? 'Search' : 'Homepage'}
                                clickTagAction="Clear Dates"
                                clickTagLabel="Flexible Booking"
                            >
                                Clear Dates
                            </Button>
                            <Button
                                id="calendar-button-handler"
                                onClick={onApplyFilters}
                                {...gaProps}
                            >
                                Apply Dates
                            </Button>
                        </ButtonGroup>
                    )}
                </>
            )}
        </div>
    );
}

export default CalendarTab;
