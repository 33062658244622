/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { BaseIcon, BaseIconsProps } from 'sarsaparilla';
import PartnerColorIconSingleton from './partnerColorIconSingleton';

type IconProps = Omit<BaseIconsProps, 'iconName' | 'iconClass' | 'alt'>;

const iconPrefix = `rec-icon`;

class PartnerColorIcon extends BaseIcon {
    constructor(props: BaseIconsProps) {
        super(props);

        this.iconSingleton = PartnerColorIconSingleton;
    }
}
export function NationalArchivesColor(props: IconProps) {
    return (
        <PartnerColorIcon
            iconName={`${iconPrefix}-national-archives-color`}
            iconClass={`${iconPrefix}-national-archives-color`}
            alt="National Archives"
            {...props}
        />
    );
}

export function DOIColor(props: IconProps) {
    return (
        <PartnerColorIcon
            iconName={`${iconPrefix}-doi-color`}
            iconClass={`${iconPrefix}-doi-color`}
            alt="Bureau of Reclamation"
            {...props}
        />
    );
}

export function TVAColor(props: IconProps) {
    return (
        <PartnerColorIcon
            iconName={`${iconPrefix}-tva-color`}
            iconClass={`${iconPrefix}-tva-color`}
            alt="Tennessee Valley Authority"
            {...props}
        />
    );
}

export function TREColor(props: IconProps) {
    return (
        <PartnerColorIcon
            iconName={`${iconPrefix}-tre-color`}
            iconClass={`${iconPrefix}-tre-color`}
            alt="Department of The Treasury"
            {...props}
        />
    );
}

export function NPSColor(props: IconProps) {
    return (
        <PartnerColorIcon
            iconName={`${iconPrefix}-nps-color`}
            iconClass={`${iconPrefix}-nps-color`}
            alt="National Park Service"
            {...props}
        />
    );
}

export function USACEColor(props: IconProps) {
    return (
        <PartnerColorIcon
            iconName={`${iconPrefix}-usace-color`}
            iconClass={`${iconPrefix}-usace-color`}
            alt="U.S. Army Corps of Engineers"
            {...props}
        />
    );
}

export function ForestServiceColor(props: IconProps) {
    return (
        <PartnerColorIcon
            iconName={`${iconPrefix}-forest-service-color`}
            iconClass={`${iconPrefix}-forest-service-color`}
            alt="Forest Service"
            {...props}
        />
    );
}

export function NOAAColor(props: IconProps) {
    return (
        <PartnerColorIcon
            iconName={`${iconPrefix}-noaa-color`}
            iconClass={`${iconPrefix}-noaa-color`}
            alt="National Oceanic and Atmospheric Administration"
            {...props}
        />
    );
}

export function BLMColor(props: IconProps) {
    return (
        <PartnerColorIcon
            iconName={`${iconPrefix}-blm-color`}
            iconClass={`${iconPrefix}-blm-color`}
            alt="Bureau of Land Management"
            {...props}
        />
    );
}

export function SmithsonianColor(props: IconProps) {
    return (
        <PartnerColorIcon
            iconName={`${iconPrefix}-smithsonian-color`}
            iconClass={`${iconPrefix}-smithsonian-color`}
            alt="Smithsonian"
            {...props}
        />
    );
}

export function FWSColor(props: IconProps) {
    return (
        <PartnerColorIcon
            iconName={`${iconPrefix}-fws-color`}
            iconClass={`${iconPrefix}-fws-color`}
            alt="Fish & Wildlife"
            {...props}
        />
    );
}

export function DOTColor(props: IconProps) {
    return (
        <PartnerColorIcon
            iconName={`${iconPrefix}-dot-color`}
            iconClass={`${iconPrefix}-dot-color`}
            alt="Department of Transportation"
            {...props}
        />
    );
}

export function BEPColor(props: IconProps) {
    return (
        <PartnerColorIcon
            iconName={`${iconPrefix}-bep-color`}
            iconClass={`${iconPrefix}-bep-color`}
            alt="Bureau of Engraving and Printing"
            {...props}
        />
    );
}

export function PTColor(props: IconProps) {
    return (
        <PartnerColorIcon
            iconName={`${iconPrefix}-pt-color`}
            iconClass={`${iconPrefix}-pt-color`}
            alt="Presidio Trust"
            {...props}
        />
    );
}

export function PTColorWithText(props: IconProps) {
    return (
        <PartnerColorIcon
            iconName={`${iconPrefix}-pt-color-with-text`}
            iconClass={`${iconPrefix}-pt-color-with-text`}
            alt="Presidio Trust"
            {...props}
        />
    );
}

export function NDWColor(props: IconProps) {
    return (
        <PartnerColorIcon
            iconName={`${iconPrefix}-ndw-color`}
            iconClass={`${iconPrefix}-ndw-color`}
            alt="Naval District Washington"
            {...props}
        />
    );
}

// cSpell:ignore NOAA, USACE
