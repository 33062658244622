/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * FAQ.tsx
 * Created by Destin Frasier on 09/06/24
 */

import './faq.scss';
import {
    HelmetWrapper,
    SingleColumnContainer,
    Heading,
    HeroImageSectionFront,
    TextBlock,
    Accordion,
    AccordionItem,
    AccordionHeading,
    AccordionContent,
    FeatureFlag,
    FlexRow,
    FlexCol,
    ExternalLink,
} from 'sarsaparilla';

export default function FAQ() {
    const flagOff = (
        <FlexRow justifyContent="center">
            <FlexCol xs="variable">
                <Heading headingLevel={2} appearance="h3">
                    Coming Soon
                </Heading>
            </FlexCol>
        </FlexRow>
    );

    const gaID = '170032257343';

    return (
        <div className="nav-faq-page">
            <HelmetWrapper title="Frequently Asked Questions" />

            <HeroImageSectionFront
                className="mb-5"
                title={'Frequently Asked Questions'}
                backgroundImagePath="/img/faq/hero/faq-hero"
                backgroundImageAltText="A valley covered with trees between mountains at sunrise"
                backgroundImageColorOverlay="#016580"
                backgroundImageColorOverlayOpacity={0.3}
            />

            <SingleColumnContainer role="main" className="mb-8">
                <FeatureFlag flag="programAndContractFaqFooterLink" fallback={flagOff}>
                    <Heading
                        headingLevel={2}
                        appearance="h4"
                        hasUnderline="left"
                        className="mb-1"
                    >
                        {process.env.SITE_NAME} Program Overview
                    </Heading>
                    <TextBlock width="xl" className="mb-8">
                        <p>
                            {process.env.SITE_NAME} is the government&apos;s centralized
                            travel planning platform and reservation system of choice for
                            14 federal agencies, offering the tools, tips, and information
                            needed for visitors to find destinations and activities, plan
                            a trip, and experience thousands of federal recreation sites
                            across the country.
                        </p>
                    </TextBlock>

                    <Heading
                        headingLevel={3}
                        appearance="h5"
                        className="mb-1"
                        hasUnderline="left"
                    >
                        General Questions About the Service
                    </Heading>
                    {/* @ts-ignore ... type in sarsa is wrong, will be fixed in next release */}
                    <Accordion
                        items={[]}
                        id="with-children"
                        shouldAllowMultipleOpen
                        className="mb-8"
                    >
                        <AccordionItem gaTrackingId={gaID}>
                            <AccordionHeading>
                                Q: What is {process.env.SITE_NAME}?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> {process.env.SITE_NAME} is, and
                                    always has been, a government service. It is the
                                    trip-planning and reservation service for 14
                                    participating agencies, nine of which offer
                                    reservations for a range of recreation opportunities.
                                    From camping to whitewater rafting to
                                    backcountry/wilderness hiking to a ranger led cave
                                    tour, {process.env.SITE_NAME} helps agencies and
                                    thousands of federal recreation locations across the
                                    country manage recreation resources and visitation by
                                    providing access to secure and compliant technical
                                    solutions as well as dedicated resources and support
                                    with training, communications, technical help, data
                                    requests, and daily operational needs.
                                </p>
                            </AccordionContent>
                        </AccordionItem>

                        <AccordionItem gaTrackingId={gaID}>
                            <AccordionHeading>
                                Q: How and when did {process.env.SITE_NAME} get started?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> The initial concept of
                                    {process.env.SITE_NAME} emerged from the desire of
                                    several agencies to provide a centralized reservation
                                    service for the public to discover and experience
                                    federal recreation destinations.
                                </p>
                                <p>
                                    In 1995, following a nationwide outdoor recreation
                                    study, the U.S. Forest Service and U.S. Army Corps of
                                    Engineers defined plans to provide one source for
                                    visitors to find and reserve campsites. They
                                    determined that the government did not have the
                                    capacity nor the expertise to build and manage a
                                    reservation system and turned to private industry
                                    through a competitive bid process to develop and
                                    deliver this valuable service.
                                </p>
                                <p>
                                    Today, {process.env.SITE_NAME} has 14 participating
                                    federal agencies and is managed under a Memorandum of
                                    Understanding (MOU) by the Recreation One Stop (R1S)
                                    Program, an interagency group of federal agency
                                    experts, with oversight by recreation leadership from
                                    the core agencies that offer reservations on{' '}
                                    {process.env.SITE_NAME}.
                                </p>
                            </AccordionContent>
                        </AccordionItem>

                        <AccordionItem gaTrackingId={gaID}>
                            <AccordionHeading>
                                Q: Are all agencies or facilities required to use{' '}
                                {process.env.SITE_NAME}?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> All the agencies and facilities
                                    that use {process.env.SITE_NAME} are voluntary
                                    participants. There is no federal mandate requiring
                                    that agencies use {process.env.SITE_NAME} and all
                                    participating agencies have chosen to be part of the
                                    program. Agencies rely on the platform’s flexibility
                                    to accommodate and enforce complex and unique
                                    requirements necessary to welcome visitors and support
                                    federal land management. Individual locations often
                                    choose {process.env.SITE_NAME} for the tools and
                                    solutions provided for visitation management, staffing
                                    and resourcing, securing financial transactions,
                                    and/or the opportunity to deliver facility cost
                                    savings and equitable access through an online
                                    service.
                                </p>
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                    <Heading
                        headingLevel={3}
                        appearance="h5"
                        className="mb-1"
                        hasUnderline="left"
                    >
                        Reservation Service and Policies
                    </Heading>
                    {/* @ts-ignore ... type in sarsa is wrong, will be fixed in next release */}
                    <Accordion
                        items={[]}
                        id="with-children"
                        shouldAllowMultipleOpen
                        className="mb-8"
                    >
                        <AccordionItem gaTrackingId={gaID}>
                            <AccordionHeading>
                                Q: Why is an account needed to make most reservations?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> Accounts are required for most
                                    reservations as they serve as the unique identifier to
                                    enforce length of stay and number of active
                                    reservation policies set by the agencies. Requiring an
                                    account is also an important safety precaution for
                                    receiving critical reservation related updates and
                                    closure information. {process.env.SITE_NAME} collects
                                    only the information necessary for these
                                    communications and protects visitor account
                                    information. {process.env.SITE_NAME} does not share
                                    account or reservation information including personal
                                    information, credit card, or financial information
                                    with other organizations, except as required by law.
                                </p>
                                <p>
                                    Visit the Help Center article{' '}
                                    <a
                                        href={`${process.env.SN_KB_EXT_URL}?id=kb_article&sys_id=c61c4ab7dbc86300c571740d0f9619b9&table=`}
                                    >
                                        How Do I Create an Account?
                                    </a>{' '}
                                    for more details.
                                </p>
                            </AccordionContent>
                        </AccordionItem>

                        <AccordionItem gaTrackingId={gaID}>
                            <AccordionHeading>
                                Q: When making a reservation, what is a booking window?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> A booking window tells you when
                                    certain dates will be available to make a reservation.
                                    The booking window is essentially the number of days,
                                    weeks, or months prior to the first reservable date
                                    that you can make a reservation for that location.
                                    Booking windows are determined by each individual
                                    facility, and different types of facilities and
                                    activities have different booking windows. You can
                                    find information about the booking window for a
                                    specific location under the Seasons & Booking tab on
                                    the facility page.
                                </p>
                                <p>
                                    Visit the Help Center article{' '}
                                    <a
                                        href={`${process.env.SN_KB_EXT_URL}?id=kb_article&sys_id=1efc3c971b405050375587bae54bcba9&table=`}
                                    >
                                        &quot;Booking Window&quot; FAQ
                                    </a>{' '}
                                    for more details.
                                </p>
                            </AccordionContent>
                        </AccordionItem>

                        <AccordionItem gaTrackingId={gaID}>
                            <AccordionHeading>
                                Q: Who is responsible for setting the policies for
                                reservations?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> The participating agencies set all
                                    policies for campgrounds, sites, permits, and
                                    activities featured on the {process.env.SITE_NAME}{' '}
                                    platform. The individual facilities that choose to use
                                    {process.env.SITE_NAME} set their own booking windows
                                    and update content, fees, reservation details, and
                                    business rules based on agency guidelines and their
                                    own local needs.
                                </p>
                                <p>
                                    Visit the{' '}
                                    <a href="/rules-reservation-policies">
                                        Rules and Reservation Policies
                                    </a>{' '}
                                    for more details about fees and other
                                    reservation-related information.
                                </p>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem gaTrackingId={gaID}>
                            <AccordionHeading>
                                Q: How does {process.env.SITE_NAME} manage site traffic
                                especially during reservation periods for high-demand
                                locations?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> There are a number of technical
                                    approaches the {process.env.SITE_NAME} team employs to
                                    support scaling for system infrastructure and manage
                                    the flow of traffic, especially when significant
                                    competition exists for a given location or experience.
                                    The team routinely scales resources up and down in
                                    response to planned, or unplanned, surges in activity.
                                </p>
                                <p>
                                    During a particularly high-demand on-sale, the visitor
                                    may encounter a “spinner” or a message that the system
                                    is processing their request. This happens as back-end
                                    processes work to ensure users are accessing inventory
                                    in a systematic way such that fair and equal access is
                                    given to all users, and to mitigate server load to
                                    provide a better user experience and prevent stress on
                                    the system during high-volume on-sales. Page layouts
                                    and the order of elements on each page are also
                                    intentionally designed and optimized to improve load
                                    speeds, a critical factor for those on-the-go or
                                    without high-speed internet.
                                </p>
                                <p>
                                    Visit these Help Center articles{' '}
                                    <a href="/articles/list/tips-for-making-campsite-reservations-at-popular-locations/281">
                                        Tips for Making Campsite Reservations at Popular
                                        Locations
                                    </a>{' '}
                                    or{' '}
                                    <a
                                        href={`${process.env.SN_KB_EXT_URL}?id=kb_article&sys_id=fc45f18a1bd254d08b9ca681f54bcb03`}
                                    >
                                        How Do I Prepare and What Can I Expect for Popular
                                        On-sales on {process.env.SITE_NAME}?
                                    </a>{' '}
                                    for more details
                                </p>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem gaTrackingId={gaID}>
                            <AccordionHeading>
                                Q: What is {process.env.SITE_NAME} doing to prevent bot
                                activity?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> The {process.env.SITE_NAME}{' '}
                                    platform does experience attempted bot activity; it is
                                    impossible not to given ever-evolving technology and
                                    advancements in AI. However, {process.env.SITE_NAME}{' '}
                                    has multiple defenses in place to detect, prevent, and
                                    mitigate the variety of bots that attempt to take
                                    advantage of the system. The primary targets of bot
                                    mitigation efforts are large-scale bot attacks on the
                                    stability of the site or bots seeking to capture
                                    multiple reservations. There is no evidence or
                                    examples of widespread abuse by bots across the
                                    platform impacting system stability, bots securing
                                    dozens of reservations, or bots securing reservations
                                    before they are released. While there are some
                                    individual reservations where basic user-written
                                    programs or more sophisticated bots may be helping a
                                    visitor secure a single reservation, this is not
                                    happening at a large scale and we are continually
                                    assessing and implementing new countermeasures and
                                    defenses to mitigate these bots when they are
                                    detected. {process.env.SITE_NAME}, and the
                                    program&apos;s technology partner Booz Allen Hamilton,
                                    strive to implement industry-leading solutions and
                                    best practices for preventing bot activity while
                                    aiming to strike a balance between providing a
                                    frictionless user experience and deploying security
                                    measures to ensure fair access to all users.
                                </p>
                                <p>
                                    Visit this Help Center article{' '}
                                    <a
                                        href={`${process.env.SN_KB_EXT_URL}?id=kb_article&sys_id=3ca5e6991b6be8d0201beb9ce54bcbcc&table=`}
                                    >
                                        Are Bots Reserving all the Popular Locations?
                                    </a>{' '}
                                    for more details.
                                </p>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem gaTrackingId={gaID}>
                            <AccordionHeading>
                                Q: How are fees set through {process.env.SITE_NAME}?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> All fees through{' '}
                                    {process.env.SITE_NAME} are managed by the
                                    participating agencies, and the majority of all funds
                                    collected through {process.env.SITE_NAME} go back to
                                    the agencies and facilities. {process.env.SITE_NAME}{' '}
                                    relies on fees collected through the service to fund
                                    the program, rather than Congressional appropriations.
                                </p>
                                <p>
                                    Visit the Help Center article{' '}
                                    <a href="/rules-reservation-policies">
                                        Rules and Reservation Policies
                                    </a>{' '}
                                    page for more details about fees and other
                                    reservation-related information.
                                </p>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem gaTrackingId={gaID}>
                            <AccordionHeading>
                                Q: How can I stay in the know about{' '}
                                {process.env.SITE_NAME} updates, releases, and new
                                locations?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> There are several ways to stay up
                                    to date with the latest information and updates from{' '}
                                    {process.env.SITE_NAME}. You can follow{' '}
                                    {process.env.SITE_NAME} on both{' '}
                                    {/* eslint-disable no-restricted-syntax */}
                                    <ExternalLink url="https://www.instagram.com/recreation_gov">
                                        Instagram
                                    </ExternalLink>{' '}
                                    {/* eslint-enable no-restricted-syntax */}
                                    and
                                    <ExternalLink
                                        url={`https://www.facebook.com/${process.env.SITE_NAME}`}
                                    >
                                        Facebook
                                    </ExternalLink>{' '}
                                    as well as sign up for travel planning ideas, helpful
                                    tips, and the latest information from{' '}
                                    {process.env.SITE_NAME} via email at{' '}
                                    <a href="/subscribe">
                                        {process.env.SITE_NAME}/subscribe
                                    </a>
                                    .
                                </p>
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                    <Heading
                        headingLevel={3}
                        appearance="h5"
                        className="mb-1"
                        hasUnderline="left"
                    >
                        {process.env.SITE_NAME} Governance
                    </Heading>
                    {/* @ts-ignore ... type in sarsa is wrong, will be fixed in next release */}
                    <Accordion
                        items={[]}
                        id="with-children"
                        shouldAllowMultipleOpen
                        className="mb-8"
                    >
                        <AccordionItem gaTrackingId={gaID}>
                            <AccordionHeading>
                                Q: Is {process.env.SITE_NAME} run by the government or a
                                private company?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> {process.env.SITE_NAME} is managed
                                    by a fully dedicated government team and staffed with
                                    resources from the participating agencies. A team of
                                    product, development, and customer engagement experts
                                    from Booz Allen Hamilton (“the Contractor”), a leading
                                    technology and digital solutions provider for the
                                    federal government, are also contracted to assist with
                                    building and maintaining the highly complex and
                                    high-quality recreation management and reservation
                                    service.
                                </p>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem gaTrackingId={gaID}>
                            <AccordionHeading>
                                Q: Why is it necessary to contract with private industry
                                for this service?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> From the beginning, the federal
                                    agencies recognized the need to bring in technical and
                                    operational expertise to build and maintain a
                                    commercial-grade online service and turned to the
                                    private sector for support. For more than 25 years,
                                    the government has worked with a series of technical
                                    solution providers to deliver this valuable service,
                                    and, in all cases, vendors were selected using full
                                    and open competition (41 U.S.C.§3301) to ensure the
                                    best and brightest technical solutions were
                                    considered.
                                </p>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem gaTrackingId={gaID}>
                            <AccordionHeading>
                                Q: How does the government ensure the contractor meets
                                contract requirements?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> The Contractor is subject to
                                    quality and performance controls under the contract
                                    and the agreement between the government and the
                                    Contractor is purposefully designed to incentivize
                                    high-quality delivery and program growth through a
                                    continuous review process to determine and award
                                    ongoing support for {process.env.SITE_NAME}.
                                </p>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem gaTrackingId={gaID}>
                            <AccordionHeading>
                                Q: How do the contractor and government teams work
                                together to meet customer needs?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> This public-private partnership
                                    drives innovation, collaboration, and solutions to
                                    field and visitor management challenges. The system
                                    has continued to scale to meet increasing demand and
                                    is continuously expanding the portfolio of inventory
                                    and services in response to feedback.
                                </p>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem gaTrackingId={gaID}>
                            <AccordionHeading>
                                Q: How is the contractor compensated for the services it
                                provides?
                            </AccordionHeading>
                            <AccordionContent>
                                <p>
                                    <strong>A:</strong> The Contractor used its own
                                    investment at the start of the contract period to
                                    design and develop a platform that met the
                                    Government&apos;s specifications. The Contractor does
                                    not receive compensation outside of the fixed
                                    line-item pricing established in the contract.
                                </p>
                                <p>
                                    No money collected for reservations through{' '}
                                    {process.env.SITE_NAME} goes directly to the
                                    Contractor. All fees collected through the platform
                                    are sent to the U.S. Treasury. The Contractor submits
                                    invoices based on the fixed line-item pricing
                                    established in the contract, and payment is made by
                                    the R1S Program Office to the Contractor pursuant to
                                    the contract to cover operating costs, manage ongoing
                                    operations, and reinvest in new technology and
                                    solutions for the platform.{' '}
                                </p>
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </FeatureFlag>
            </SingleColumnContainer>
        </div>
    );
}
