/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import {
    Recaptcha,
    StyledModal,
    Button,
    Box,
    Stack,
    Icon,
    Heading,
    Text,
} from 'sarsaparilla';

const propTypes = {
    isOpen: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    solved: PropTypes.bool,
};

export default function RecaptchaModal({ isOpen = false, closeModal, solved = false }) {
    return (
        <StyledModal
            size="sm"
            isOpen={isOpen}
            shouldCloseOnOverlayClick={false}
            shouldShowCloseButton={false}
            aria-label="We need to verify that you’re a human"
        >
            <Stack>
                <Box alignX="center" style={{ color: '#d14529' }}>
                    <Icon iconName="warning" size="lg" />
                </Box>

                <Stack space="xl">
                    <Stack space="xxs">
                        <Heading
                            headingLevel={1}
                            appearance="h5"
                            style={{ textAlign: 'center' }}
                        >
                            We need to verify that you’re a human
                        </Heading>
                        <Text style={{ textAlign: 'center' }}>
                            Please complete the challenge.
                        </Text>
                    </Stack>
                    <Box alignX="center">
                        <Recaptcha />
                    </Box>
                    <Button shouldFitContainer onClick={closeModal} isDisabled={!solved}>
                        Continue
                    </Button>
                </Stack>
            </Stack>
        </StyledModal>
    );
}

RecaptchaModal.propTypes = propTypes;
