/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import { round } from 'lodash';

type PropTypes = {
    value: number;
};
export default class StarDisplay extends React.PureComponent<PropTypes> {
    render() {
        const { value } = this.props;
        const decVal = value % 1;
        let wholeVal = Math.round(value);
        let halfString = '';
        if (decVal >= 0.25 && decVal < 0.75) {
            wholeVal = Math.trunc(value);
            halfString = '_5';
        }
        return (
            <div className="ratview-star-display-wrapper">
                <div className={`sprite star-rating rating_${wholeVal}${halfString}`}>
                    <span>{round(value, 1)} out of 5 stars</span>
                </div>
            </div>
        );
    }
}
