/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { MENU_SECTIONS, CAMPING_SECTION } from '../constants/menuSections';

export function getMenuSections(enableNewCampingFilters: boolean) {
    if (enableNewCampingFilters) {
        const menuSections = [...MENU_SECTIONS];
        menuSections.splice(0, 1, CAMPING_SECTION);
        return menuSections;
    }
    return MENU_SECTIONS;
}
