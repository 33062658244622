/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Stack,
    Box,
    Button,
    ButtonGroup,
    StickyBottomHelperNew,
    Drawer,
    Heading,
    Text,
    FauxImage,
    DividerNew as Divider,
} from 'sarsaparilla';

export function MobileProceedToCart({
    onProceedToCartClick,
    heading,
    parentName,
    children,
    imageUrl,
    proceedToCartButtonText,
    isProceedToCartDisabled,
    isWaitingForServerResponse,
    isDrawerWrapFullWidth,
}) {
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const addonDrawerWrapClasses = isDrawerWrapFullWidth ? '--full-width' : ''; // Set drawer-wrap full width when is needed

    return (
        <div data-shared-summary-mobile-wrapper>
            <StickyBottomHelperNew>
                <div data-component="MobileProceedToCart">
                    <div className="shared-ods-m-inner">
                        <Box
                            className="shared-bottom-box"
                            padding="sm"
                            background="white"
                        >
                            <Drawer
                                isOpen={isDrawerOpen}
                                onClose={() => {
                                    setDrawerOpen(false);
                                }}
                                triggerId="show-summary-button"
                                label="Order summary"
                            >
                                <div
                                    className={`shared-mobile-proceed-to-cart-drawer-wrap${addonDrawerWrapClasses}`}
                                >
                                    <Stack>
                                        <FauxImage
                                            aspectRatio="21:9"
                                            src={imageUrl}
                                            altText={heading}
                                        />
                                        <Stack space="xxs">
                                            <Heading headingLevel={1} appearance="h6">
                                                {heading}
                                                <span className="rec-sr-only">
                                                    {' '}
                                                    Order Details Summary
                                                </span>
                                            </Heading>
                                            <Text size="sm" color="gray">
                                                Part of {parentName}
                                            </Text>
                                        </Stack>
                                        <Divider />
                                        {children}
                                    </Stack>
                                </div>
                            </Drawer>
                            <ButtonGroup isStretchedToFit isFullWidthOnMobile={false}>
                                <Button
                                    size="lg"
                                    appearance="link"
                                    onClick={() => {
                                        setDrawerOpen((prev) => !prev);
                                    }}
                                    id="show-summary-button"
                                >
                                    {isDrawerOpen ? 'Hide Summary' : 'Show Summary'}
                                </Button>
                                <Button
                                    isLoading={isWaitingForServerResponse}
                                    size="lg"
                                    isDisabled={isProceedToCartDisabled}
                                    onClick={() => {
                                        setDrawerOpen(false);
                                        onProceedToCartClick();
                                    }}
                                >
                                    {proceedToCartButtonText}
                                </Button>
                            </ButtonGroup>
                        </Box>
                    </div>
                </div>
            </StickyBottomHelperNew>
        </div>
    );
}

MobileProceedToCart.propTypes = {
    onProceedToCartClick: PropTypes.func,
    heading: PropTypes.node,
    parentName: PropTypes.node,
    children: PropTypes.node,
    imageUrl: PropTypes.string,
    isWaitingForServerResponse: PropTypes.bool,
    proceedToCartButtonText: PropTypes.string,
    isProceedToCartDisabled: PropTypes.bool,
    isDrawerWrapFullWidth: PropTypes.bool,
};
