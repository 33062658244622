/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';

import { Button, Icon, PlaceholderText } from 'sarsaparilla';

export function BackToLink({ isLoading, backTo = '', onClick }) {
    if (isLoading) {
        return <PlaceholderText fontSize={12} lineHeight={20.4} width="25rem" />;
    }
    return (
        <Button
            data-shared-back-to-search-button
            appearance="link"
            iconBeforeElement={<Icon iconName="arrow-back" />}
            role="link"
            onClick={onClick}
        >
            {`Back to ${backTo}`}
        </Button>
    );
}

BackToLink.propTypes = {
    isLoading: PropTypes.bool,
    backTo: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

export default BackToLink;
