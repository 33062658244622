/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import {
    Box,
    Stack,
    StarRating,
    Button,
    Heading,
    InlineBulletList,
    ImageUnavailable,
    useId,
} from 'sarsaparilla';

import CardLink from './CardLink';

function getPriceString({ priceNum, minPriceNum, maxPriceNum }) {
    const hasSimplePrice = !isNil(priceNum);
    const hasFullPriceRange = !isNil(minPriceNum) && !isNil(maxPriceNum);

    if (hasFullPriceRange && minPriceNum >= 0) {
        if (minPriceNum === maxPriceNum || minPriceNum > maxPriceNum) {
            return `$${Math.floor(minPriceNum)}`;
        }

        return `$${Math.floor(minPriceNum)} - $${Math.floor(maxPriceNum)}`;
    }

    if (hasSimplePrice) {
        return `$${Math.floor(priceNum)}`;
    }

    return null;
}

export default function SearchResultCard({
    id: userId,
    linkUrl,
    imageUrl,
    heading,
    headingLevel = 2,
    metaItems = [],
    featureItems = [],
    children,
    rating,
    reviewCount,
    priceNum,
    minPriceNum,
    maxPriceNum,
    pricePerUnit,
    availability,
    className,
    actionButtonText = 'Book Now',
    isLinkToSameService = true,
    ...rest
}) {
    const priceString = getPriceString({ priceNum, minPriceNum, maxPriceNum });
    const id = useId(userId);

    const renderCardImage = () => {
        if (isNil(imageUrl)) {
            return <ImageUnavailable isStretchy />;
        }

        return (
            //TODO: pass alt text into aria-label instead of header title
            <div
                role="img"
                aria-label={heading}
                className="image"
                style={{
                    backgroundImage: `url("${imageUrl}")`,
                }}
            />
        );
    };

    const renderBottomContent = () => {
        if (priceString) {
            return (
                <div className="price">
                    {priceString}
                    {!!pricePerUnit && (
                        <span className="price-per-unit">{`/ ${pricePerUnit}`}</span>
                    )}
                </div>
            );
        }

        return (
            <Button
                to={isLinkToSameService ? linkUrl : null}
                href={isLinkToSameService ? null : linkUrl}
                aria-label={`${actionButtonText} - ${heading}`}
                size="xs"
            >
                {actionButtonText}
            </Button>
        );
    };

    return (
        <div data-component="SearchResultCard" className={className} {...rest}>
            <div className="with-sidebar search-result-card-anchor">
                <div className="sidebar card-link">{renderCardImage()}</div>
                <div className="not-sidebar">
                    <div className="details">
                        <Box padding={['sm', 'md']}>
                            <Stack space="sm">
                                <div>
                                    <Heading headingLevel={headingLevel} appearance="h5">
                                        <CardLink
                                            id={id}
                                            linkUrl={linkUrl}
                                            ariaLabel={heading}
                                            isLinkToSameService={isLinkToSameService}
                                        >
                                            {heading}
                                        </CardLink>
                                    </Heading>
                                    {!!availability && (
                                        <Box
                                            shadow={1}
                                            className="availability"
                                            border={
                                                availability.toUpperCase() ===
                                                'UNAVAILABLE'
                                                    ? 'red'
                                                    : 'gray'
                                            }
                                            background={
                                                availability.toUpperCase() ===
                                                'UNAVAILABLE'
                                                    ? 'light-red'
                                                    : 'white'
                                            }
                                        >
                                            {availability}
                                        </Box>
                                    )}
                                    {!isEmpty(metaItems) && (
                                        <InlineBulletList className="meta-list mt-half">
                                            {metaItems.map((item, index) => (
                                                <li key={index}>{item}</li>
                                            ))}
                                        </InlineBulletList>
                                    )}
                                </div>
                                <div>
                                    {!isEmpty(featureItems) && (
                                        <InlineBulletList className="features-list">
                                            {featureItems.map((item, index) => (
                                                <li key={index}>{item}</li>
                                            ))}
                                        </InlineBulletList>
                                    )}

                                    {children}
                                </div>
                            </Stack>
                        </Box>
                        <Box
                            className="bottom-bar"
                            background="gray-1"
                            paddingY="xs"
                            paddingX={['sm', 'md']}
                        >
                            <StarRating rating={rating} reviewCount={reviewCount} />
                            {renderBottomContent()}
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    );
}

SearchResultCard.propTypes = {
    id: PropTypes.string,
    // url route for card link and book now button
    linkUrl: PropTypes.string.isRequired,
    // heading title text
    heading: PropTypes.string.isRequired,
    // url path to image
    imageUrl: PropTypes.string,
    // heading tag (example <h3>)
    headingLevel: PropTypes.number,
    // list items like bulleted times
    metaItems: PropTypes.array,
    // feature list items
    featureItems: PropTypes.array,
    // star rating section
    rating: PropTypes.number,
    // number of reviews showed next to star rating icons
    reviewCount: PropTypes.number,

    priceNum: PropTypes.number,
    minPriceNum: PropTypes.number,
    maxPriceNum: PropTypes.number,
    pricePerUnit: PropTypes.string,
    // tag that shows on image
    availability: PropTypes.string,
    className: PropTypes.string,
    actionButtonText: PropTypes.string,
    children: PropTypes.node,
    contentAriaLabel: PropTypes.string,

    /** If true, renders react router Links to preserve redux state */
    isLinkToSameService: PropTypes.bool,
};
