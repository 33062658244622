/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    CalendarDate,
    parseDate,
    parseAbsolute,
    getDayOfWeek,
} from '@internationalized/date';
import { DateValue } from 'react-aria';

type DateRangeUnit = 'month' | 'year' | 'week' | 'day';
type DateFormatType =
    | 'MM/DD/YYYY'
    | 'YYYY-MM-DD'
    | 'MMM DD'
    | 'MMM'
    | 'D'
    | 'ISO'
    | 'LL'
    | 'ddd'
    | 'MMMM D'
    | 'MMMM'
    | 'YYYY'
    | 'MM-YYYY'
    | 'YYYY-MM-DD[T]HH:mm:ss[Z]'
    | 'MM-DD-YYYY';

export const getTimeZone = (): string => {
    try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York';
    } catch {
        return 'America/New_York';
    }
};

export const fromCalendarDate = (
    date: CalendarDate | null,
    format: DateFormatType = 'MM/DD/YYYY',
    tz?: string
): string | null => {
    if (!date) return null;

    try {
        const month = String(date.month).padStart(2, '0');
        const day = String(date.day).padStart(2, '0');
        const year = date.year;
        const timezone = tz ?? getTimeZone();

        switch (format) {
            case 'MM/DD/YYYY':
                return `${month}/${day}/${year}`;
            case 'YYYY-MM-DD':
                return `${year}-${month}-${day}`;
            case 'MM-YYYY':
                return `${month}-${year}`;
            case 'MM-DD-YYYY':
                return `${month}-${day}-${year}`;
            case 'ddd': {
                const dddFormatter = new Intl.DateTimeFormat('en-US', {
                    weekday: 'short',
                });
                return dddFormatter.format(date.toDate(timezone));
            }
            case 'MMMM D': {
                const mmmmDFormatter = new Intl.DateTimeFormat('en-US', {
                    month: 'long',
                    day: 'numeric',
                });
                return mmmmDFormatter.format(date.toDate(timezone));
            }
            case 'YYYY-MM-DD[T]HH:mm:ss[Z]':
                return `${year}-${month}-${day}T00:00:00Z`;
            case 'ISO':
                return `${year}-${month}-${day}T00:00:00.000Z`;
            case 'LL': {
                const llFormatter = new Intl.DateTimeFormat('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                });

                return llFormatter.format(date.toDate(timezone));
            }
            case 'MMM': {
                const dateV = date.toDate(timezone);
                return new Intl.DateTimeFormat('en-US', { month: 'short' }).format(dateV);
            }
            case 'D': {
                const dateValue = date.toDate(timezone);
                return dateValue.getDate().toString();
            }
            case 'MMMM':
                return new Intl.DateTimeFormat('en-US', {
                    month: 'long',
                }).format(date.toDate(timezone));
            case 'YYYY':
                return date.year.toString();
            case 'MMM DD': {
                const formatter = new Intl.DateTimeFormat('en-US', {
                    month: 'short',
                    day: 'numeric',
                });
                return formatter.format(date.toDate(timezone));
            }
            default:
                throw new Error('Unsupported output format');
        }
    } catch {
        return null;
    }
};

class DateOperationsClass extends CalendarDate {
    constructor(private date: CalendarDate) {
        super(date.year, date.month, date.day);
    }

    add(duration: {
        days?: number;
        months?: number;
        years?: number;
        weeks?: number;
    }): DateOperationsClass {
        if (typeof duration === 'object' && 'weeks' in duration && duration.weeks) {
            return new DateOperationsClass(super.add({ days: duration.weeks * 7 }));
        }
        return new DateOperationsClass(super.add(duration));
    }

    subtract(duration: {
        days?: number;
        months?: number;
        years?: number;
        weeks?: number;
    }): DateOperationsClass {
        if (typeof duration === 'object' && 'weeks' in duration && duration.weeks) {
            return new DateOperationsClass(super.add({ days: -(duration.weeks * 7) }));
        }
        return new DateOperationsClass(
            super.add({
                days: duration.days ? -duration.days : undefined,
                months: duration.months ? -duration.months : undefined,
                years: duration.years ? -duration.years : undefined,
            })
        );
    }

    tz(
        date: CalendarDate | string | Date | null,
        format: Parameters<typeof fromCalendarDate>[1] = 'MM/DD/YYYY',
        timezone?: string
    ): string | null {
        const tz = timezone || getTimeZone();

        try {
            Intl.DateTimeFormat(undefined, { timeZone: tz });
        } catch {
            throw new Error(`Invalid timezone: ${tz}`);
        }

        if (!date) return null;

        const calendarDate = toSearchCalendarDate(date);
        if (!calendarDate) return null;

        const result = fromCalendarDate(calendarDate, format, tz);
        if (!result) {
            throw new Error('Error formatting date');
        }
        return result;
    }

    startOf(unit: DateRangeUnit): DateOperationsClass {
        switch (unit) {
            case 'month':
                return new DateOperationsClass(
                    new CalendarDate(this.year, this.month, 1)
                );
            case 'year':
                return new DateOperationsClass(new CalendarDate(this.year, 1, 1));
            case 'week': {
                const newDate = new CalendarDate(this.year, this.month, this.day);
                const dayOfWeek = getDayOfWeek(newDate, 'en-US');
                return new DateOperationsClass(super.add({ days: -dayOfWeek }));
            }
            case 'day':
                return new DateOperationsClass(
                    new CalendarDate(this.year, this.month, this.day)
                );
            default:
                return new DateOperationsClass(
                    new CalendarDate(this.year, this.month, this.day)
                );
        }
    }

    endOf(unit: DateRangeUnit): DateOperationsClass {
        switch (unit) {
            case 'month':
                return new DateOperationsClass(
                    new CalendarDate(this.year, this.month, 1)
                        .add({ months: 1 })
                        .add({ days: -1 })
                );
            case 'year':
                return new DateOperationsClass(new CalendarDate(this.year, 12, 31));
            case 'week':
                return this.startOf('week').add({ days: 6 });
            case 'day':
                return new DateOperationsClass(
                    new CalendarDate(this.year, this.month, this.day)
                );
            default:
                return new DateOperationsClass(
                    new CalendarDate(this.year, this.month, this.day)
                );
        }
    }

    format(format: Parameters<typeof fromCalendarDate>[1] = 'MM/DD/YYYY'): string {
        const result = fromCalendarDate(this, format);
        if (!result) {
            throw new Error('Error formatting date');
        }
        return result;
    }

    utc(): DateOperationsClass {
        const utcDate = this.toDate('UTC');
        return new DateOperationsClass(
            new CalendarDate(
                utcDate.getUTCFullYear(),
                utcDate.getUTCMonth() + 1,
                utcDate.getUTCDate()
            )
        );
    }

    isSame(date: string | Date | CalendarDate | DateValue | null): boolean {
        const compareDate = toSearchCalendarDate(date);
        if (!compareDate) return false;

        return (
            this.year === compareDate.year &&
            this.month === compareDate.month &&
            this.day === compareDate.day
        );
    }

    set(duration: { day?: number; month?: number; year?: number }): CalendarDate {
        return this.date.set(duration);
    }

    static isValid(date: DateValue | string | Date | CalendarDate | null): boolean {
        if (!date) return false;

        if (date instanceof CalendarDate) {
            return true;
        }

        if (
            typeof date === 'object' &&
            'year' in date &&
            'month' in date &&
            'day' in date
        ) {
            try {
                // Validate year
                if (!Number.isInteger(date.year)) return false;

                // Validate month (1-12)
                if (!Number.isInteger(date.month) || date.month < 1 || date.month > 12)
                    return false;

                // Validate day based on month and year
                const daysInMonth = new Date(date.year, date.month, 0).getDate();
                if (!Number.isInteger(date.day) || date.day < 1 || date.day > daysInMonth)
                    return false;

                return true;
            } catch {
                return false;
            }
        }

        // Handle Date object
        if (date instanceof Date) {
            return !Number.isNaN(date.getTime());
        }

        // Handle string
        try {
            const parsed = new Date(date);
            return !Number.isNaN(parsed.getTime());
        } catch {
            return false;
        }
    }

    isValid(): boolean {
        return DateOperationsClass.isValid(this);
    }
}

export const toSearchCalendarDate = (
    dateInput: string | Date | CalendarDate | DateValue | null
): CalendarDate => {
    if (!dateInput) {
        const newDate = new Date();
        return parseDate(newDate.toISOString().split('T')[0]);
    }

    try {
        if (dateInput instanceof CalendarDate) {
            return dateInput;
        }

        if (dateInput instanceof Date) {
            return parseDate(dateInput.toISOString().split('T')[0]);
        }

        if (
            typeof dateInput === 'object' &&
            'year' in dateInput &&
            'month' in dateInput &&
            'day' in dateInput
        ) {
            return new DateOperationsClass(
                new CalendarDate(dateInput.year, dateInput.month, dateInput.day)
            );
        }

        const dateStr = dateInput.trim();

        // Matches YYYY-MM-DD, YYYY-MM-DDTHH:mm:ss, YYYY-MM-DDTHH:mm:ssZ
        const isoRegex = /^(\d{4}-\d{2}-\d{2})(T\d{2}:\d{2}:\d{2})?Z?$/;
        if (isoRegex.test(dateStr)) {
            // Extract just the date part
            const datePart = dateStr.split('T')[0];
            return parseDate(datePart);
        }

        // Matches MM-YYYY
        const mmYyyyRegex = /^(\d{2})-(\d{4})$/;
        if (mmYyyyRegex.test(dateStr)) {
            const [, month, year] = dateStr.match(mmYyyyRegex) || [];
            return new DateOperationsClass(
                new CalendarDate(parseInt(year, 10), parseInt(month, 10), 1)
            );
        }

        // MM/DD/YYYY or M/D/YYYY
        if (dateStr.includes('/')) {
            const [month, day, year] = dateStr
                .split('/')
                .map((part) => parseInt(part, 10));
            // Convert to ISO format first
            const isoStr = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
            return parseDate(isoStr);
        }

        // MM-DD-YYYY or M-D-YYYY
        if (dateStr.includes('-')) {
            // If it's already ISO format (YYYY-MM-DD)
            if (dateStr.length === 10 && dateStr[4] === '-') {
                return parseDate(dateStr);
            }

            // Convert MM-DD-YYYY to ISO
            const [month, day, year] = dateStr
                .split('-')
                .map((part) => parseInt(part, 10));
            const isoStr = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
            return parseDate(isoStr);
        }

        if (!Number.isNaN(Date.parse(dateStr))) {
            const timezone = getTimeZone();
            const zonedDateTime = parseAbsolute(
                new Date(dateStr).toISOString(),
                timezone
            );
            return new CalendarDate(
                zonedDateTime.year,
                zonedDateTime.month,
                zonedDateTime.day
            );
        }

        throw new Error('Unsupported date format');
    } catch {
        const newDate = new Date();
        return parseDate(newDate.toISOString().split('T')[0]);
    }
};

export const searchDate = (
    dateInput?: string | Date | CalendarDate | null | DateValue
): DateOperationsClass => {
    const date = dateInput ?? new Date();
    const calendarDate = toSearchCalendarDate(date);
    return new DateOperationsClass(calendarDate);
};
