/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * IconSingleton.js
 * Created by michael bray on 7/9/17.
 */

import axios from 'axios';
import xmldoc from 'xmldoc';
import { uniqueId } from 'lodash';

export class IconSingleton {
    subscribers: any;

    svgCache: any;

    svgLoaded: boolean;

    svgRequested: boolean;

    notificationName: string;

    svgPath: string;

    constructor() {
        this.subscribers = {};
        this.svgCache = {};
        this.svgLoaded = false;
        this.svgRequested = false;

        this.notificationName = 'icons.loaded';
        this.svgPath = `${process.env.CDN}/graphics/icons.svg`;
    }

    downloadIcons() {
        this.svgRequested = true;
        axios
            .get(this.svgPath, {
                withCredentials: true,
            })
            .then((res) => {
                // parse the response
                this.parseSvg(res.data);

                // mark the SVG as loaded
                this.svgLoaded = true;

                // notify any other icon components that the SVG data is ready
                this.notifySubscribers(this.notificationName);
            })
            .catch(() => {
                // There's an issue on tests where after downloading the icons
                // there's a notification but since the component is sometimes
                // unmounted calling `setState` throws an error, hence in the mocks
                // for the icons SVG file loading the request is rejected and
                // handled in this catch block
            });
    }

    parseSvg(rawSvg: string) {
        // downloaded raw SVG data, send it through an XML parser
        const data = new xmldoc.XmlDocument(rawSvg);

        // iterate through each symbol and extract the symbol's content XML as a string and
        // also its viewbox attribute
        data.childrenNamed('symbol').forEach((symbol: any) => {
            let childData = '';
            symbol.eachChild((child: any) => {
                childData += child.toString();
            });

            // save all this data into the svg data singleton
            this.svgCache[symbol.attr.id] = {
                data: childData,
                viewBox: symbol.attr.viewBox,
            };
        });
    }

    notifySubscribers(event: any) {
        // iterate through subscribers to notify them that icons are ready
        for (const subscriptionId in this.subscribers) {
            if ({}.hasOwnProperty.call(this.subscribers, subscriptionId)) {
                const subscriber = this.subscribers[subscriptionId];
                subscriber(event);
            }
        }
    }

    subscribe(subscriber: any) {
        // add a subscriber and return a UUID as a subscription ID so they can later unsubscribe
        const subscriptionId = uniqueId();
        this.subscribers[subscriptionId] = subscriber;
        return subscriptionId;
    }

    unsubscribe(subscriptionId: any) {
        // unsubscribe the observer
        delete this.subscribers[subscriptionId];
    }
}

const instance = new IconSingleton();
export default instance;

// cSpell:ignore viewbox, xmldoc
