/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
    Box,
    Heading,
    Icon,
    Button,
    Stack,
    Inline,
    useId,
    Placeholder,
    PlaceholderText,
    FlexRow,
    useBreakpoint,
} from 'sarsaparilla';

import ConfirmDeleteModal from './ConfirmDeleteModal';

export function OrderDetailsSection({
    children,
    heading,
    isOpen = true,
    onModifyClick,
    onRemoveClick,
    isLoading,
    modifyText = 'Modify',
    useRemoveConfirmationModal = true,
    parentSelector,
}) {
    const [isExpanded, setExpanded] = useState(isOpen);
    const [isConfirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const id = useId();
    const { isMdAndDown } = useBreakpoint();

    useEffect(() => {
        setExpanded(isOpen);
    }, [isOpen]);

    const hasControlButtons = !!onModifyClick || !!onRemoveClick;

    if (isLoading) {
        return <OrderDetailsSectionPlaceholder />;
    }

    const openConfirmDeleteModal = () => {
        setConfirmDeleteOpen(true);
    };

    return (
        <section data-component="OrderDetailsSection">
            <Box
                background="light-blue"
                border="blue"
                padding="sm"
                alignX="space-between"
                alignY="flex-start"
                className={cx({ 'remove-flex': isMdAndDown })}
            >
                <Heading
                    headingLevel={2}
                    appearance="h5"
                    className="order-details-section-heading"
                >
                    <button
                        aria-controls={id}
                        type="button"
                        aria-expanded={isExpanded}
                        data-shared-ods-accordion-trigger
                        onClick={() => setExpanded((prev) => !prev)}
                    >
                        <span>
                            <Icon
                                iconName={isExpanded ? 'chevron-down' : 'chevron-right'}
                                size="md"
                            />
                            <span>{heading}</span>
                        </span>
                    </button>
                </Heading>
                <FlexRow className={cx({ 'pl-one-and-half pr-1': isMdAndDown })}>
                    {hasControlButtons && (
                        <Inline
                            noWrap
                            space={['sm', 'sm', 'lg']}
                            className="OrderDetailsSection-button-wrap mr-1"
                        >
                            {!!onModifyClick && (
                                <Button
                                    aria-label={`${modifyText}: ${heading}`}
                                    size="md"
                                    iconBeforeElement={<Icon iconName="edit" />}
                                    appearance="link"
                                    onClick={onModifyClick}
                                >
                                    <span>{modifyText}</span>
                                </Button>
                            )}
                            {!!onRemoveClick && (
                                <Button
                                    aria-label={`Remove ${heading}`}
                                    size="md"
                                    iconBeforeElement={<Icon iconName="delete" />}
                                    appearance="link"
                                    onClick={
                                        useRemoveConfirmationModal
                                            ? openConfirmDeleteModal
                                            : onRemoveClick
                                    }
                                >
                                    <span>Remove</span>
                                </Button>
                            )}
                        </Inline>
                    )}
                </FlexRow>
            </Box>
            {isExpanded && (
                <Box id={id} padding="md" border="gray" className="shared-expand-section">
                    <Stack>{children}</Stack>
                </Box>
            )}

            <ConfirmDeleteModal
                isOpen={isConfirmDeleteOpen}
                parentSelector={parentSelector}
                onClose={() => {
                    setConfirmDeleteOpen(false);
                }}
                onRemoveClick={onRemoveClick}
            />
        </section>
    );
}

OrderDetailsSection.propTypes = {
    children: PropTypes.node,
    heading: PropTypes.node,
    isOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    onRemoveClick: PropTypes.func,
    onModifyClick: PropTypes.func,
    modifyText: PropTypes.node,
    useRemoveConfirmationModal: PropTypes.bool,
    /** Used by ui-widget for the Modal to change the parent element that the modal will be attached to */
    parentSelector: PropTypes.func,
};

function OrderDetailsSectionPlaceholder() {
    return (
        <section data-component="OrderDetailsSection">
            <Box border="gray">
                <Placeholder height="5rem" />
            </Box>
            <Box padding="md" border="gray" className="shared-expand-section">
                <Stack>
                    <PlaceholderText lines={3} />
                    <PlaceholderText lines={2} />
                </Stack>
            </Box>
        </section>
    );
}
